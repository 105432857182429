import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ILink } from '@ncg/data';

import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-dealer',
    template: `
        <ng-container *ngIf="dealerLink && dealerLink.url">
            <ng-container *ngIf="isPartOfNav">
                <a role="menuitem" class="navbar-item has--icon" [routerLink]="dealerLink.url" routerLinkActive="is-active">
                    <span class="navbar-item--icon icon dealer-icon" aria-hidden="true">
                        <ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
                    </span>
                    <span class="navbar-item--text" *ngIf="dealerLink.name">{{ dealerLink.name }}</span>
                </a>
            </ng-container>

            <ng-container *ngIf="!isPartOfNav">
                <a role="menuitem" class="dealer" [ngClass]="{ 'dealer--has-name': isNameVisible }" [routerLink]="[dealerLink.url]">
                    <span class="icon dealer-icon" aria-hidden="true">
                        <ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
                    </span>
                    <span class="dealer__name" *ngIf="isNameVisible && dealerLink.name">{{ dealerLink.name }}</span>
                </a>
            </ng-container>
        </ng-container>

        <ng-template #iconTmpl>
            <svg-icon-sprite
                [src]="spotsConfig.iconType === 'outlined' ? 'locations-outlined' : 'dealer-icon'"
                [viewBox]="'0 0 30 30'"
                [width]="'30px'"
                [height]="'30px'"
                aria-hidden="true"
                class="is-flex"
            ></svg-icon-sprite>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealerComponent {
    @Input() isNameVisible?: boolean;
    @Input() isPartOfNav?: boolean;
    @Input() dealerLink?: ILink;

    constructor(@Inject(SpotsConfig) public spotsConfig: SpotsConfig) {}
}
