import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ILink, IOfferCarViewModel, IOfferDto, ModelsListItemSimple, NcgLocationFormCategory } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { randomId } from '../core/helpers';
import { MetaService } from '../core/meta.service';
import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { FormService } from './form.service';

@Component({
    selector: 'ncg-offer-form',
    template: `
        <div #mainElement class="offer-form" *ngIf="form">
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!states.submitted; else success">
                <ng-container
                    *ngTemplateOutlet="headerTmpl; context: { title: 'sidepanel.offer_header', subtitle: 'sidepanel.offer_subtitle' }"
                ></ng-container>
                <ng-container *ngIf="!models?.length">
                    <ng-container *ngTemplateOutlet="modelPreviewTmpl"></ng-container>
                </ng-container>
                <div class="field" *ngIf="models?.length">
                    <div class="control">
                        <ncg-models-select [parentForm]="form" [isTouched]="form.get('vehicle')?.touched" [models]="models!"></ncg-models-select>
                    </div>
                </div>
                <div class="field" *ngIf="isCashAllowed || isLoanAllowed || isLeasingAllowed">
                    <label class="label">{{ 'forms.financetype_interest' | translate }}</label>
                    <div class="control">
                        <label class="checkbox" *ngIf="isCashAllowed">
                            <input formControlName="is_cash_allowed" name="is_cash_allowed" type="checkbox" />
                            <span class="checkmark"></span> {{ 'forms.financetype_cash' | translate }}
                        </label>
                        <label class="checkbox" *ngIf="isLoanAllowed">
                            <input formControlName="is_loan_allowed" name="is_loan_allowed" type="checkbox" />
                            <span class="checkmark"></span> {{ 'forms.financetype_loan' | translate }}
                        </label>
                        <label class="checkbox" *ngIf="isLeasingAllowed">
                            <input formControlName="is_leasing_allowed" name="is_leasing_allowed" type="checkbox" />
                            <span class="checkmark"></span> {{ 'forms.financetype_leasing' | translate }}
                        </label>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="firstNameId" class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            [id]="firstNameId"
                            data-testid="firstname"
                            name="firstname"
                            formControlName="firstname"
                            class="input"
                            [class.is-danger]="errors('firstname')"
                            type="text"
                            required
                            [attr.aria-invalid]="errors('firstname')"
                            [attr.aria-errormessage]="errors('firstname') ? firstNameId + '_error' : null"
                        />
                        <p [id]="firstNameId + '_error'" class="help is-danger" *ngIf="errors('firstname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="lastNameId" class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input
                            [id]="lastNameId"
                            data-testid="lastname"
                            name="lastname"
                            formControlName="lastname"
                            class="input"
                            [class.is-danger]="errors('lastname')"
                            type="text"
                            required
                            [attr.aria-invalid]="errors('lastname')"
                            [attr.aria-errormessage]="errors('lastname') ? lastNameId + '_error' : null"
                        />
                        <p [id]="lastNameId + '_error'" class="help is-danger" *ngIf="errors('lastname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="emailId" class="label">{{ 'forms.email' | translate }}*</label>
                        <input
                            [id]="emailId"
                            data-testid="email"
                            name="email"
                            formControlName="email"
                            class="input"
                            [class.is-danger]="errors('email')"
                            type="email"
                            required
                            [attr.aria-invalid]="errors('email')"
                            [attr.aria-errormessage]="errors('email') ? emailId + '_error' : null"
                        />
                        <p [id]="emailId + '_error'" class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <div class="field">
                            <label [for]="phoneId" class="label">{{ 'forms.mobile' | translate }}*</label>
                            <!-- country code input -->
                            <div class="phone">
                                <span class="phoneCode">
                                    <input
                                        style="width: 100px; margin-right: 2px"
                                        type="country-code"
                                        autocomplete="tel-country-code"
                                        class="input"
                                        name="selectedCountryCode"
                                        formControlName="selectedCountryCode"
                                        [class.is-danger]="errors('selectedCountryCode')"
                                        [attr.aria-invalid]="errors('selectedCountryCode')"
                                        [attr.aria-errormessage]="errors('selectedCountryCode') ? 'countryCode_error' : null"
                                    />
                                </span>
                                <span class="phoneOnly">
                                    <input
                                        [id]="phoneId"
                                        data-testid="phone"
                                        name="phone"
                                        formControlName="phone_mobile"
                                        class="input"
                                        type="tel"
                                        autocomplete="tel-national"
                                        [class.is-danger]="errors('phone_mobile')"
                                        [attr.aria-invalid]="errors('phone_mobile')"
                                        [attr.aria-errormessage]="errors('phone_mobile') ? phoneId + '_error' : null"
                                    />
                                </span>
                            </div>
                        </div>
                        <p id="countryCode_error" class="help is-danger" *ngIf="errors('selectedCountryCode')">
                            {{ 'forms.error_phone_country' | translate }}
                        </p>
                        <p [id]="phoneId + '_error'" class="help is-danger" *ngIf="errors('phone_mobile')">{{ 'forms.error_phone' | translate }}</p>
                    </div>
                </div>
                <div class="field" *ngIf="allowSelectLocation">
                    <div class="control">
                        <ncg-location-select
                            [parentForm]="form"
                            [isTouched]="form.get('location')?.touched"
                            [category]="category"
                            [helpTextLocation]="'forms.offer_location_text' | translate"
                            (isLocationsFound)="onLocationState($event)"
                        ></ncg-location-select>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="licensePlateId" class="label">{{ 'forms.license_plate_optional' | translate }}</label>
                        <input
                            [id]="licensePlateId"
                            data-testid="license_plate"
                            name="license_plate"
                            formControlName="license_plate"
                            class="input"
                            type="text"
                            [class.is-danger]="errors('license_plate')"
                            [attr.aria-invalid]="errors('license_plate')"
                            [attr.aria-errormessage]="errors('license_plate') ? licensePlateId + '_error' : null"
                        />
                        <p [id]="licensePlateId + '_error'" class="help">{{ 'forms.offer_license_plate_text' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="messageId" class="label">{{ 'forms.message_optional' | translate }}</label>
                        <textarea
                            [id]="messageId"
                            data-testid="message"
                            name="message"
                            formControlName="message"
                            class="textarea"
                            rows="6"
                        ></textarea>
                    </div>
                </div>
                <div class="field is-grouped is-grouped-centered is-not-column">
                    <div class="control">
                        <button data-testid="submit" class="button is-primary" [class.is-loading]="states.processing" [disabled]="states.processing">
                            {{ 'forms.submit_offer' | translate }}
                        </button>
                    </div>
                </div>
                <div class="notification is-primary" *ngIf="errorState">
                    <ng-container *ngIf="errorState === 'api'; else genericError"> {{ 'forms.error_field' | translate }} </ng-container>
                    <ng-template #genericError>{{ 'forms.error_submit' | translate }}</ng-template>
                </div>
                <ncg-legal *ngIf="privacyPolicy" [legalText]="privacyPolicy"></ncg-legal>
            </form>
            <ng-template #success>
                <ng-container
                    *ngTemplateOutlet="headerTmpl; context: { title: 'sidepanel.offer_success_header', subtitle: 'sidepanel.offer_success_subtitle' }"
                ></ng-container>
                <ng-container *ngTemplateOutlet="modelPreviewTmpl"></ng-container>
                <ncg-rich-text *ngIf="successMessage" [html]="successMessage"></ncg-rich-text>
                <p>
                    {{ 'sidepanel.offer_success_text' | translate: { location: location?.value } }}
                </p>
            </ng-template>
            <ng-template #modelPreviewTmpl>
                <div class="model-preview model-preview--with-margin" *ngIf="model && model.image">
                    <figure class="model-preview__figure is-ratio-hd">
                        <img [attr.alt]="model.image?.altText || model.name" [attr.src]="model.image?.url" loading="lazy" *ngIf="model.image" />
                    </figure>
                    <div class="model-preview__header">
                        <div class="model-preview__header--name">{{ model.name }}</div>
                        <div class="model-preview__header--subtitle" *ngIf="model.subtitle">{{ model.subtitle }}</div>
                    </div>
                </div>
            </ng-template>
            <ng-template #headerTmpl let-title="title" let-subtitle="subtitle">
                <header class="aside-header" *ngIf="title || subtitle">
                    <h1 *ngIf="title" class="aside-header__title">
                        {{ title | translate }}
                    </h1>
                    <h2 *ngIf="subtitle" class="aside-header__subtitle">
                        {{ subtitle | translate }}
                    </h2>
                </header>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferFormComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    @Input() successMessage?: string;
    @Input() model?: IOfferCarViewModel;
    @Input() redirect?: ILink;
    @Input() models?: ModelsListItemSimple[];
    @Input() isCashAllowed = false;
    @Input() isLoanAllowed = false;
    @Input() isLeasingAllowed = false;
    @Input() campaign?: string;
    @Input() listId?: string;
    @ViewChild('mainElement') private mainElement?: ElementRef<HTMLDivElement>;

    allowSelectLocation = true;
    form = this.fb.group(
        {
            is_cash_allowed: [this.isCashAllowed, Validators.required],
            is_loan_allowed: [this.isLoanAllowed, Validators.required],
            is_leasing_allowed: [this.isLeasingAllowed, Validators.required],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.email, Validators.required, FormService.emailValidator()]],
            selectedCountryCode: ['', [Validators.required, FormService.countryCodeValidator()]],
            phone_mobile: ['', [Validators.required, FormService.countryPhoneValidator(''), FormService.numbersOnlyValidator()]],
            consent: '',
            location: ['', [Validators.required]],
            license_plate: '',
            message: '',
            vehicle: '',
        },
        { updateOn: 'change' }
    );

    get location() {
        return this.form.get('location');
    }

    get vehicle() {
        return this.form.get('vehicle');
    }

    states = {
        submitted: false,
        processing: false,
    };

    errorState: '' | 'api' | 'server' = '';
    isLocationsDisabled = true;
    category: NcgLocationFormCategory = 'retail';
    privacyPolicy = '';

    firstNameId: string;
    lastNameId: string;
    emailId: string;
    phoneId: string;
    licensePlateId: string;
    messageId: string;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly formService: FormService,
        private readonly translateService: TranslateService,
        private readonly metaService: MetaService,
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService,
        private readonly scrollService: ScrollService
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    ngOnInit() {
        this.firstNameId = randomId('firstname');
        this.lastNameId = randomId('lastname');
        this.emailId = randomId('email');
        this.phoneId = randomId('phone');
        this.licensePlateId = randomId('license_plate');
        this.messageId = randomId('message');

        if (this.models?.length) {
            this.vehicle?.setValidators(Validators.required);
        }

        if (this.model?.location) {
            this.location?.patchValue(this.model.location);
            this.location?.updateValueAndValidity();
            this.allowSelectLocation = false;
        }

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.privacyPolicy = settings.privacyPolicy;
                this.cd.markForCheck();
                // Patch the form value inside the settings subscribe block
                this.form?.patchValue({
                    selectedCountryCode: FormService.convertCountryCodeToPhoneCode(settings.seoCountry),
                });
            });
    }

    onLocationState(event: any) {
        this.isLocationsDisabled = event;

        if (!this.isLocationsDisabled && this.form) {
            this.form.disable();
            this.errorState = 'server';
        }
    }

    onSubmit(): void {
        if (this.states.processing) {
            return;
        }

        this.errorState = '';
        this.cd.markForCheck();

        if (!this.form) {
            return;
        }

        const value = this.form.value;

        if (this.models?.length) {
            const selectedModel = value.vehicle as ModelsListItemSimple;
            this.model = {
                id: selectedModel.lms || '',
                isLeasingAllowed: this.isLeasingAllowed,
                isUsedCar: false,
                name: selectedModel.name,
                subtitle: '',
                image: selectedModel.image,
            };
        }

        if (this.form.invalid || this.form.disabled || !this.model) {
            FormService.markControlsAsTouched(this.form);
            return;
        }

        this.states.processing = true;

        const customNcgOriginName = window.location.host.replace('www.', '');

        const financeTypes = [];

        if (value.is_cash_allowed) {
            financeTypes.push(this.translateService.instant('forms.financetype_cash'));
        }

        if (value.is_loan_allowed) {
            financeTypes.push(this.translateService.instant('forms.financetype_loan'));
        }

        if (value.is_leasing_allowed) {
            financeTypes.push(this.translateService.instant('forms.financetype_leasing'));
        }

        const model: IOfferDto = {
            financeTypes: financeTypes.join(', '),
            origin: customNcgOriginName,
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email.toLowerCase(),
            phone_mobile: `${value.selectedCountryCode}${value.phone_mobile}`,
            consent: value.consent || undefined,
            storeCode: value.location.store_code,
            vehicle: this.model.id,
            licensePlate: value.license_plate,
            message: value.message,
            url: this.metaService.getAbsoluteUrlWithoutQueryString(),
            isUsed: this.model.isUsedCar,
            list: this.listId,
            meta: {
                campaign: {
                    utm: this.trackingService.getUtmValue(),
                    title: this.campaign,
                },
            },
        };

        firstValueFrom(this.formService.submitOffer(model, `${this.model.name} ${this.model.subtitle}`.trim()))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;

                this.errorState = 'server';
                if (err.validationErrors.length) {
                    this.errorState = 'api';
                    if (this.form) {
                        FormService.markValidationErrors(err.validationErrors, this.form);
                    }
                }

                this.cd.markForCheck();
            })
            .then(() => {
                this.scrollService.scrollToElement(this.mainElement?.nativeElement, { block: 'center' });
            });
    }

    private onSuccess() {
        const url = this.redirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);
}
