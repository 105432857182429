import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { IModelPageResponse } from '@ncg/data';
import { ScrollService } from '../../core/scroll.service';

import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-model-page-nav',
    template: `
        <div
            [ngClass]="{
                'model-page__navbar-scroll': useScrollIndicator,
                'model-page__navbar-scroll--both': useScrollIndicator && !isMaxLeft && !isMaxRight,
                'model-page__navbar-scroll--right': useScrollIndicator && isMaxLeft,
                'model-page__navbar-scroll--left': useScrollIndicator && isMaxRight
            }"
        >
            <nav
                #nav
                class="model-page__navbar navbar"
                [ngClass]="{ invert: spotsConfig.invertModelPageHeader }"
                aria-label="car model navigation"
                (scroll)="handleScroll()"
            >
                <a
                    class="navbar-item"
                    [ngClass]="{ invert: spotsConfig.invertModelPageHeader }"
                    [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="is-active"
                    [routerLink]="[modelPage.modelParentUrl]"
                >
                    <span>{{ 'models.model_overview' | translate }}</span>
                </a>
                <a
                    *ngFor="let link of modelPage.pageNavigation"
                    class="navbar-item"
                    routerLinkActive="is-active"
                    [routerLink]="[link.url]"
                    [ngClass]="{ invert: spotsConfig.invertModelPageHeader }"
                >
                    <span>{{ link.name }}</span>
                </a>
                <a
                    *ngIf="modelPage.allowTestDrive"
                    class="navbar-item"
                    [ngClass]="{ invert: spotsConfig.invertModelPageHeader }"
                    (click)="openTestDrive()"
                >
                    <span>{{ 'models.book_test_drive' | translate }}</span>
                </a>
                <a
                    *ngIf="modelPage.allowSendOffer"
                    class="navbar-item"
                    [ngClass]="{ invert: spotsConfig.invertModelPageHeader }"
                    (click)="openOffer()"
                >
                    <span>{{ 'models.send_offer' | translate }}</span>
                </a>
            </nav>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageNavComponent implements AfterViewInit {
    @ViewChild('nav') navRef?: ElementRef;
    useScrollIndicator = false;
    isMaxLeft = false;
    isMaxRight = false;
    @Input() modelPage: IModelPageResponse;
    @Output() openOfferClicked = new EventEmitter();
    @Output() openTestDriveClicked = new EventEmitter<string>();

    constructor(
        private readonly scrollService: ScrollService,
        @Inject(SpotsConfig) public readonly spotsConfig: SpotsConfig
    ) {}

    ngAfterViewInit() {
        this.scrollToActiveLink();
        this.handleScroll();
    }

    handleScroll(): void {
        if (!this.navRef || !this.navRef.nativeElement) {
            return;
        }

        const { scrollLeft, scrollWidth, clientWidth } = this.navRef.nativeElement;

        if (scrollWidth <= clientWidth) {
            this.useScrollIndicator = false;
            return;
        }

        this.useScrollIndicator = true;

        const maxScrollLeft = scrollWidth - clientWidth;

        this.isMaxLeft = scrollLeft === 0;
        this.isMaxRight = Math.round(scrollLeft) >= maxScrollLeft;
    }

    scrollToActiveLink() {
        setTimeout(() => {
            if (this.navRef && this.navRef.nativeElement) {
                const navElement: HTMLElement = this.navRef.nativeElement;
                const activeLink: HTMLLinkElement | null = navElement.querySelector('.is-active');
                if (activeLink) {
                    let scrollNumber;
                    const btnPadding = 20; // used for to show some of the next menu item
                    const navWidth = navElement.offsetWidth;
                    const navOffsetLeft = navElement.scrollLeft;
                    const activeLinkLeft: number = activeLink.offsetLeft;
                    const activeLinkWidth: number = activeLink.offsetWidth;

                    if (activeLinkWidth + activeLinkLeft > navWidth + navOffsetLeft) {
                        scrollNumber = activeLinkWidth + activeLinkLeft - navWidth;
                        scrollNumber = scrollNumber + btnPadding;
                    } else if (navOffsetLeft > activeLinkLeft) {
                        scrollNumber = activeLinkLeft;
                        scrollNumber = scrollNumber - btnPadding;
                    } else {
                        return;
                    }

                    this.scrollService.scrollToPosition({ left: scrollNumber, behavior: 'auto' }, navElement);
                }
            }
        });
    }

    openTestDrive(): void {
        this.openTestDriveClicked.emit('clicked');
    }

    openOffer(): void {
        this.openOfferClicked.emit('clicked');
    }
}
