import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { PageResponse } from '@ncg/data';

import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { SpotsConfig } from '../spots/spots-config';

@Component({
    selector: 'ncg-breadcrumb',
    template: `
        <ng-container *ngIf="data.breadcrumb && data.breadcrumb.length > 0">
            <ngx-json-ld [json]="schema" *ngIf="schema"></ngx-json-ld>
            <ng-container *ngIf="settingsService.get() | async as settings">
                <div
                    *ngIf="settings.featureToggles.isBreadcrumbEnabled && !this.data.hideBreadcrumb"
                    class="breadcrumb"
                    [ngClass]="{
                        'container is-fullwidth': !(data.template === 'filterPage' && data.layout === 'filterVisible'),
                        'filter-page-breadcrumb--full': data.template === 'filterPage' && data.layout === 'filterVisible',
                        'filter-page-breadcrumb--panel': data.template === 'filterPage' && data.layout !== 'filterVisible',
                        'breadcrumb--scroll-fade-both': useScrollIndicator && !isMaxLeft && !isMaxRight,
                        'breadcrumb--scroll-fade-right': useScrollIndicator && isMaxLeft,
                        'breadcrumb--scroll-fade-left': useScrollIndicator && isMaxRight
                    }"
                >
                    <div
                        class="breadcrumb__background"
                        [ngClass]="{
                            'breadcrumb__background--dark': hasFade && isLight,
                            'breadcrumb__background--light': hasFade && !isLight,
                            'breadcrumb__background--overlaid': isOverlaid
                        }"
                    >
                        <ol #breadcrumbRef class="breadcrumb__list" (scroll)="onListScroll()" aria-label="Breadcrumbs">
                            <li class="breadcrumb__item" *ngFor="let item of data.breadcrumb; first as isFirst">
                                <a
                                    class="breadcrumb__item-link"
                                    [routerLink]="[item.value]"
                                    [ngClass]="{
                                        'breadcrumb__item-link--inverse': isLight,
                                        'breadcrumb__item-link--active': !isLight && item.value === data.url,
                                        'breadcrumb__item-link--active-inverse': isLight && item.value === data.url
                                    }"
                                >
                                    <svg-icon-sprite
                                        *ngIf="!isFirst"
                                        src="chevron-right"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'18px'"
                                        [height]="'18px'"
                                        aria-hidden="true"
                                    ></svg-icon-sprite>
                                    <span>{{ item.key }}</span>
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
    private bcRef: ElementRef | undefined;

    @Input()
    public data: PageResponse;

    @ViewChild('breadcrumbRef')
    public set breadcrumbRef(ref: ElementRef) {
        if (!ref || !ref.nativeElement) {
            return;
        }
        this.bcRef = ref;
        this.scrollToActiveLink();
    }

    public schema: BreadcrumbJson | undefined;
    public useScrollIndicator = false;
    public isMaxLeft = false;
    public isMaxRight = false;
    public isOverlaid: boolean | undefined;
    public isLight: boolean | undefined;
    public hasFade: boolean | undefined;

    constructor(
        private readonly scrollService: ScrollService,
        private readonly cd: ChangeDetectorRef,
        @Inject(SpotsConfig)
        private readonly spotsConfig: SpotsConfig,
        public readonly settingsService: SettingsService
    ) {}

    public ngOnInit(): void {
        if (!this.data.breadcrumb) {
            return;
        }

        const firstSpotAlias = this.data.grid?.[0]?.content[0]?.alias ?? '';

        this.isOverlaid =
            (this.data.breadCrumbIsOverlaid && ['extendedhero', 'bydhero'].includes(firstSpotAlias)) ||
            (this.spotsConfig.isHeroFullViewport && firstSpotAlias === 'hero');
        this.isLight = this.isOverlaid && this.data.breadCrumbIsLight;
        this.hasFade = this.isOverlaid && this.data.breadCrumbHasFade;

        // JSON+LD
        this.schema = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': this.data.breadcrumb.map((item, index) => ({
                '@type': 'ListItem',
                'position': index + 1,
                'item': {
                    '@id': item.value,
                    'name': item.key,
                },
            })),
        };

        this.cd.markForCheck();
    }

    public onListScroll(): void {
        if (!this.bcRef || !this.bcRef.nativeElement) {
            return;
        }

        const { scrollLeft, scrollWidth, clientWidth } = this.bcRef.nativeElement as HTMLElement;

        if (scrollWidth <= clientWidth) {
            this.useScrollIndicator = false;
            return;
        }

        this.useScrollIndicator = true;

        const maxScrollLeft = scrollWidth - clientWidth;

        this.isMaxLeft = scrollLeft === 0;
        this.isMaxRight = Math.round(scrollLeft) >= maxScrollLeft;
    }

    private scrollToActiveLink(): void {
        if (!this.bcRef || !this.bcRef.nativeElement) {
            return;
        }

        const { nativeElement } = this.bcRef;
        const activeLink: HTMLLinkElement | null = nativeElement.querySelector('.breadcrumb__item-link--active');

        if (activeLink && nativeElement.scrollWidth > nativeElement.clientWidth) {
            const scrollLeft = activeLink.offsetLeft;
            if (scrollLeft > 0) {
                this.scrollService.scrollToPosition({ left: scrollLeft, behavior: 'auto' }, nativeElement);
            }
        }
    }
}

export interface BreadcrumbJson {
    '@context': string;
    '@type': string;
    'itemListElement': {
        '@type': string;
        'position': number;
        'item': {
            '@id': string;
            'name': string;
        };
    }[];
}
