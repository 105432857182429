import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ILink } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingsService } from '../../core/settings.service';
import { ProductService } from '../../products/product.service';
import { SpotsConfig } from '../../spots/spots-config';

@Component({
    selector: 'ncg-meta-menu-cars',
    template: `
        <a role="menuitem" class="navbar-item has--icon" [routerLink]="carsLink?.url" routerLinkActive="is-active" *ngIf="carsLink?.url">
            <span class="car-icon icon navbar-item--icon ng-star-inserted" aria-hidden="true">
                <span *ngIf="amount" class="car-icon--tag">{{ amount }}</span>
                <ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
            </span>
            <span class="navbar-item--text">
                {{ carsLink?.name ? carsLink?.name : ('navigation.used_cars_link_text' | translate) }}
                <span aria-hidden="true" class="navbar-item__active-bar"></span>
            </span>
        </a>

        <ng-template #iconTmpl>
            <svg-icon-sprite
                [src]="spotsConfig.iconType === 'filled' ? 'car' : 'car-outlined'"
                [viewBox]="'0 0 30 30'"
                [width]="'30px'"
                [height]="'30px'"
                aria-hidden="true"
                class="is-flex"
            ></svg-icon-sprite>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetaMenuCarsComponent implements OnInit, OnDestroy {
    carsLink?: ILink;
    amount?: number;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private productService: ProductService,
        private readonly settingsService: SettingsService,
        private readonly cd: ChangeDetectorRef,
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig
    ) {}

    ngOnInit() {
        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.carsLink = settings.usedCarsLink;
                this.cd.markForCheck();
            });

        this.productService
            .getUsedCarsAmount()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.amount = data?.amount;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
