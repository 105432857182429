import { AnimationEvent } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ILink, INavigationResponse } from '@ncg/data';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ScrollService } from '../../core/scroll.service';

import { SettingsService } from '../../core/settings.service';
import { ImpactOverlayRef } from '../../overlay/overlay-ref';
import { ImpactOverlayLeave } from '../../overlay/overlay.interfaces';
import { IMPACT_OVERLAY_DATA } from '../../overlay/overlay.tokens';
import { SpotsConfig } from '../../spots/spots-config';
import { mobileAnimation } from '../../utils/animations/mobile-menu.animations';
import { NavigationService } from '../navigation.service';

enum ACTIVE_VIEW {
    NEXT = 'next',
    CURRENT = 'current',
}

@Component({
    selector: 'ncg-mobile-menu',
    template: `
        <aside
            #mobileMenu
            class="mobile-menu"
            [ngClass]="{ 'mobile-menu--first': breadcrumbList.length === 0 }"
            [@slideContent]="{
                value: this.animationState,
                params: {
                    xAxis: this.spotsConfig.mobileAnimationXAxis,
                    yAxis: this.spotsConfig.mobileAnimationYAxis
                }
            }"
            (@slideContent.start)="onAnimationStart($event)"
            (@slideContent.done)="onAnimationDone($event)"
            *ngIf="menuList"
        >
            <nav class="nav" aria-label="mobile navigation" #mobileNav>
                <ncg-mobile-header
                    *ngIf="data?.hasMobileHeader"
                    [logoName]="data?.logoName"
                    [isActiveCloseButton]="isActiveCloseButton"
                ></ncg-mobile-header>

                <ng-container *ngIf="!spotsConfig.isMobileBackMultiLevel">
                    <div class="mobile-menu__header--level-back">
                        <ng-container *ngTemplateOutlet="breadcrumbTmpl"></ng-container>
                    </div>
                </ng-container>

                <div class="mobile-menu__wrapper">
                    <!-- current mobile nav step -->
                    <ncg-mobile-nav-list
                        [ngClass]="{ 'mobile-menu__parent': breadcrumbList.length === 0 }"
                        class="mobile-menu__holder {{ currentAnimation }}"
                        (navigateTo)="onNavigateTo($event)"
                        [menuList]="currentMenuList"
                        [currentItem]="currentItem"
                        [isTopLevelList]="breadcrumbList.length === 0"
                        #activeMenu
                        [@navigate]="currentAnimation"
                        (@navigate.done)="navigateDone($event)"
                        [attr.inert]="currentAnimation !== 'active' ? true : null"
                    >
                        <div *ngIf="!data?.hasMobileHeader && breadcrumbList.length === 0" class="mobile-menu__header--title">
                            {{ 'header.menu' | translate }}
                        </div>
                        <ng-container *ngIf="spotsConfig.isMobileBackMultiLevel">
                            <ng-container *ngTemplateOutlet="breadcrumbTmpl"></ng-container>
                        </ng-container>
                    </ncg-mobile-nav-list>

                    <!-- Next mobile nav step -->
                    <ncg-mobile-nav-list
                        class="mobile-menu__holder {{ nextAnimation }}"
                        (navigateTo)="onNavigateTo($event)"
                        #nextMenu
                        [menuList]="nextMenuList"
                        [currentItem]="currentItem"
                        [isTopLevelList]="breadcrumbList.length === 0"
                        [@navigate]="nextAnimation"
                        (@navigate.done)="navigateDone($event)"
                        [attr.inert]="currentAnimation === 'active' ? true : null"
                    >
                        <ng-container *ngIf="spotsConfig.isMobileBackMultiLevel">
                            <ng-container *ngTemplateOutlet="breadcrumbTmpl"></ng-container>
                        </ng-container>
                    </ncg-mobile-nav-list>
                </div>
            </nav>
        </aside>

        <ng-template #breadcrumbTmpl>
            <div class="mobile-menu__breadcrumb" *ngIf="breadcrumbList.length">
                <ng-container *ngFor="let breadcrumb of breadcrumbList; let isLast = last">
                    <button
                        class="mobile-menu__item back"
                        (click)="onBackTo(breadcrumb?.name ? breadcrumb : undefined)"
                        *ngIf="spotsConfig.isMobileBackMultiLevel"
                    >
                        <ng-container *ngTemplateOutlet="iconBackTmpl"></ng-container>
                        {{ breadcrumb?.name || 'navigation.mobile_main_menu' | translate }}
                    </button>

                    <button
                        class="mobile-menu__item back"
                        (click)="onBackTo(breadcrumb?.name ? breadcrumb : undefined)"
                        *ngIf="!spotsConfig.isMobileBackMultiLevel && isLast"
                    >
                        <ng-container *ngTemplateOutlet="iconBackTmpl"></ng-container>
                        {{ 'navigation.back' | translate }}
                    </button>
                </ng-container>
            </div>
        </ng-template>

        <ng-template #iconBackTmpl>
            <svg-icon-sprite
                [src]="spotsConfig.iconType === 'light' ? 'chevron-light-left' : 'chevron-left'"
                [viewBox]="'0 0 30 30'"
                [width]="'30px'"
                [height]="'30px'"
                aria-hidden="true"
                class="is-flex mobile-menu__back-icon"
            ></svg-icon-sprite>
        </ng-template>
    `,
    animations: [mobileAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnInit, OnDestroy, ImpactOverlayLeave {
    @ViewChild('mobileNav') mobileNav: ElementRef;
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    animationStateChanged = new EventEmitter<AnimationEvent>();
    menuList!: INavigationResponse[];

    active: ACTIVE_VIEW = ACTIVE_VIEW.CURRENT;

    animationWay!: string;

    currentAnimation = 'active';
    currentMenuList: INavigationResponse[] | undefined = [];
    nextAnimation = 'right';
    nextMenuList: INavigationResponse[] | undefined = [];
    currentItem?: INavigationResponse | any;

    activePageName?: string;

    isActiveCloseButton = false;
    breadcrumbList: INavigationResponse[] | any = [];
    dealerLink?: ILink;

    private isAnimating = false;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private readonly dialogRef: ImpactOverlayRef,
        private readonly navigationService: NavigationService,
        private readonly settingsService: SettingsService,
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private readonly scrollService: ScrollService
    ) {}

    ngOnInit() {
        this.getMobileMenu();

        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.dealerLink = settings.dealershipsLink;
                this.cd.markForCheck();
            });

        this.router.events.pipe(take(1), takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.dialogRef?.componentInstance) {
                this.dialogRef.close();
            }
        });
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const { key } = event;

        switch (key) {
            case 'Esc':
            case 'Escape':
                if (this.dialogRef?.componentInstance) {
                    this.dialogRef.close();
                }
                break;
        }
    }

    private getMobileMenu() {
        this.navigationService
            .getNavigation()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((menu) => {
                this.menuList = menu || [];
                this.currentMenuList = this.menuList;
                this.cd.markForCheck();
            });
    }

    onAnimationStart(event: AnimationEvent) {
        if (event.toState === 'leave') {
            this.isActiveCloseButton = false;
        }

        if (event.toState === 'enter') {
            this.isActiveCloseButton = true;
        }

        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';

        this.cd.detectChanges();
    }

    onNavigateTo(item: INavigationResponse) {
        this.animationWay = 'toLeft';

        const list = item.children?.length ? item.children : this.menuList;

        if (this.currentAnimation === 'active') {
            this.nextMenuList = list;
            this.nextAnimation = 'right';
            this.animateNavStep(item, 'active', 'left', ACTIVE_VIEW.NEXT);
        } else {
            this.currentMenuList = list;
            this.currentAnimation = 'right';
            this.animateNavStep(item, 'left', 'active', ACTIVE_VIEW.CURRENT);
        }
    }

    scrollToTop() {
        const nav = this.mobileNav?.nativeElement as HTMLElement;
        this.scrollService.scrollToPosition({ top: 0, behavior: 'auto' }, nav);
    }

    onBackTo(item: INavigationResponse | undefined) {
        this.animationWay = 'toRight';

        let list = [];

        if (!item) {
            this.currentItem = undefined;
            list = this.menuList ? this.menuList : [];
        } else {
            this.currentItem = item;
            list = item && item.children ? item.children : [];
        }

        if (this.currentAnimation === 'active') {
            this.nextMenuList = list;
            this.nextAnimation = 'left';
            this.animateNavStep(item, 'active', 'right', ACTIVE_VIEW.NEXT);
        } else {
            this.currentMenuList = list;
            this.currentAnimation = 'left';
            this.animateNavStep(item, 'right', 'active', ACTIVE_VIEW.CURRENT);
        }
    }

    navigateDone(event: AnimationEvent) {
        // Update the animation after each animation to put into the right place to the next animation
        if (event.toState === 'active' && this.animationWay === 'toLeft') {
            if (this.currentAnimation !== 'active') {
                this.currentAnimation = 'right';
            }

            if (this.nextAnimation !== 'active') {
                this.nextAnimation = 'right';
            }
        }

        if (event.toState === 'active' && this.animationWay === 'toRight') {
            if (this.currentAnimation !== 'active') {
                this.currentAnimation = 'left';
            }

            if (this.nextAnimation !== 'active') {
                this.nextAnimation = 'left';
            }
        }

        this.isAnimating = false;
        this.triggerReflow();
    }

    /**
     * IOS hack, to make scroll work in navigation slides
     * Link: https://stackoverflow.com/a/3485654/10498549
     */
    triggerReflow() {
        const nav: HTMLElement = this.mobileNav.nativeElement;
        if (nav) {
            nav.style.display = 'inline-block';
            this.cd.detectChanges();
            (window as any).temp = nav.offsetHeight; // no need to store this anywhere, the reference is enough
            nav.style.display = '';
            this.cd.detectChanges();
            delete (window as any).temp;
        }
    }

    private animateNavStep(item: INavigationResponse | undefined, firstTo: string, secondTo: string, active: ACTIVE_VIEW) {
        // if is animation don't do anything
        if (this.isAnimating) {
            return;
        }

        // mark the active screen
        this.active = active;

        // update the animating state to avoid overlap animations (only for prevention)
        this.isAnimating = true;

        // the new position each element should move
        this.nextAnimation = firstTo;
        this.currentAnimation = secondTo;

        // Update the breadcrumb with the new item
        this.updateBreadcrumb(item);

        // Scroll to top of new nav list
        this.scrollToTop();

        this.cd.detectChanges();
    }

    private updateBreadcrumb(item?: INavigationResponse) {
        // When the selected item go to deeper into the childrens (navigate to)
        if (this.animationWay === 'toLeft') {
            if (this.currentItem) {
                // use the old currentItem to save on the breadcrumb menu
                this.breadcrumbList.push(this.currentItem);
            } else {
                // this will be the first item which redirect to the root;
                this.breadcrumbList.push({});
            }
        }

        // store the new currentItem;
        this.currentItem = item;

        // When the selected item go to upper into the children (back navigation)
        if (this.animationWay === 'toRight') {
            const breadcrumItemIndex = this.currentItem ? this.breadcrumbList.lastIndexOf(this.currentItem) : 0;
            const numOfItems = this.breadcrumbList.length - breadcrumItemIndex;
            this.breadcrumbList.splice(breadcrumItemIndex, numOfItems);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
