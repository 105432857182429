import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
    IBookServiceDto,
    IConfigurationDto,
    IInquiryDto,
    INewsletterDto,
    IOfferDto,
    IServiceBookingDto,
    ITestDriveDto,
    IUnsubscribeNewsletterDto,
    NcgConsent,
    NcgLocationFormCategory,
} from '@ncg/data';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { IValidationError } from '../core/app-http-error.response';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';

@Injectable({ providedIn: 'root' })
export class FormService implements OnDestroy {
    private static readonly countryToPhoneCodeMap: { [key: string]: string } = {
        dk: '+45',
        se: '+46',
        fi: '+358',
        no: '+47',
        de: '+49',
        lv: '+371',
        lt: '+370',
        ee: '+372',
    };

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly http: HttpClient,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService
    ) {}

    public static emailValidator() {
        return Validators.pattern('^[a-zA-Z0-9_&\\-+]+(\\.[a-zA-Z0-9_&\\-+]+)*@[a-zA-Z0-9\\-]+(\\.[a-zA-Z0-9\\-]+)*\\.[a-zA-Z]{2,32}$');
    }

    public static convertCountryCodeToPhoneCode(countryCode: string): string {
        return countryCode ? this.countryToPhoneCodeMap[countryCode.toLowerCase()] || '' : '';
    }
    public static numbersOnlyValidator() {
        return Validators.pattern('^[0-9]*$');
    }
    public static countryCodeValidator() {
        return Validators.pattern(/^\+\d{1,3}$/);
    }

    // Returns phone country code and phone number if string contains a countrycode
    static splitPhonenumber(phoneNumber: string, countryCode: string): [string, string] | null {
        const escapedCountryCode = this.countryToPhoneCodeMap[countryCode.toLowerCase()].replace(/\+/g, '\\+');
        const pattern = new RegExp(`^(${escapedCountryCode})(\\d+)$`);
        const match = phoneNumber.match(pattern);
        if (match) {
            const [, phoneCountryCode, remainingNumber] = match;
            return [phoneCountryCode, remainingNumber];
        } else {
            return null;
        }
    }

    public static countryPhoneValidator(defaultCountryCode: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const phone = control.value?.toString();
            const countryCode = control.parent?.get('selectedCountryCode')?.value || defaultCountryCode;
            const convertedCountryCode = countryCode.replace('+', '');

            if (convertedCountryCode === '45' && phone?.length !== 8) {
                return { invalidPhone: true };
            }
            return null;
        };
    }
    public static markControlsAsTouched(form: UntypedFormGroup): void {
        for (const control in form.controls) {
            if (form.controls[control].pristine) {
                form.controls[control].markAsTouched();
                form.controls[control].updateValueAndValidity();
            }
        }
    }

    public static markValidationErrors(validationErrors: IValidationError[], form: UntypedFormGroup) {
        validationErrors.forEach((validationError) => {
            const field = form.get(validationError.property);
            if (field) {
                field.setErrors({ required: validationError.error });
            }
        });
    }

    public static errors(controlName: string, form?: UntypedFormGroup) {
        const control = form?.controls[controlName];
        if (control) {
            if (control.invalid && (control.dirty || control.touched)) {
                return control.errors;
            }
        } else {
            console.warn(`Field validation error: "${controlName}" not found.`);
        }
        return null;
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public zipCodeLookup(zipCode?: string | number | null | undefined): Promise<string | undefined> {
        if (!zipCode) {
            return Promise.resolve(undefined);
        }
        return firstValueFrom(this.http.get(`https://api.dataforsyningen.dk/postnumre/${zipCode}`))
            .then(({ navn }: { navn?: string }) => {
                if (navn) {
                    return navn;
                }
                return undefined;
            })
            .catch((err) => {
                console.warn(err);
                return undefined;
            });
    }

    public getConsent(identifier?: string | null): Observable<NcgConsent> {
        const params: any = {};
        if (identifier) {
            params.identifier = identifier;
        }

        return this.http.get<NcgConsent>('/api/consent', {
            params,
        });
    }

    public submitInquiry(model: IInquiryDto, category: NcgLocationFormCategory): Observable<boolean> {
        const label = 'skriv til ' + (category === 'workshop' ? 'vaerksted' : 'forhandler');

        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                model.culture = culture;
                return this.http.post<boolean>('/api/inquiry', model).pipe(
                    map(() => {
                        this.trackingService.trackFormSubmission({
                            eventCategory: 'dealer contact',
                            eventAction: label,
                            eventLabel: label,
                            email: model.email,
                        });
                        if (model.consent) {
                            this.trackingService.trackEmailSignup(model.email, label, 'permissions');
                        }
                        return true;
                    })
                );
            }),
            takeUntil(this.unsubscribe)
        );
    }

    public submitNewsletter(model: INewsletterDto): Observable<boolean> {
        if (!model.consent) {
            return of(false);
        }

        return this.http.post<boolean>('/api/newsletter', model).pipe(
            map(() => {
                this.trackingService.trackEmailSignup(model.email, 'permissions', 'permissions', true);
                return true;
            })
        );
    }

    public submitUnsubscribeNewsletter(model: IUnsubscribeNewsletterDto): Observable<boolean> {
        return this.http
            .delete<boolean>('/api/newsletter', {
                params: {
                    email: model.email,
                },
            })
            .pipe(
                map(() => {
                    this.trackingService.trackUnsubscribeEmail('unsubscribe', 'unsubscribed newsletter');
                    return true;
                })
            );
    }

    // #BOOKSERVICE: Slightly different payloads, but same endpoint
    public submitBookService(model: IBookServiceDto): Observable<boolean> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                model.culture = culture;
                return this.http.post<boolean>('/api/book-service', model).pipe(
                    map(() => {
                        this.trackingService.trackFormSubmission({
                            eventCategory: 'service booking',
                            eventAction: 'service booking',
                            eventLabel: 'service booking',
                            email: model.email,
                        });
                        if (model.consent) {
                            this.trackingService.trackEmailSignup(model.email, 'service booking', 'permissions');
                        }
                        return true;
                    }),
                    takeUntil(this.unsubscribe)
                );
            })
        );
    }

    // #BOOKSERVICE: Slightly different payloads, but same endpoint
    public submitServiceBooking(model: IServiceBookingDto, storecode: string): Observable<boolean> {
        return this.settingsService.getCulture().pipe(
            switchMap((culture) => {
                model.culture = culture;
                return this.http.post<boolean>(`/api/book-service/${storecode}`, model).pipe(
                    map(() => {
                        this.trackingService.trackFormSubmission({
                            eventCategory: 'service booking',
                            eventAction: 'service booking',
                            eventLabel: 'service booking',
                            email: model.email,
                        });
                        if (model.consent) {
                            this.trackingService.trackEmailSignup(model.email, 'service booking', 'permissions');
                        }
                        return true;
                    })
                );
            })
        );
    }

    public submitTestDrive(model: ITestDriveDto, modelName: string, isSidePanel: boolean): Observable<boolean> {
        return this.http.post<boolean>('/api/test-drive', model).pipe(
            map(() => {
                const action = model.isUsed ? 'test drive used' : 'test drive';

                this.trackingService.trackFormSubmission({
                    eventCategory: 'test drive',
                    eventAction: action,
                    eventLabel: modelName,
                    slideIn: `${isSidePanel}`,
                    email: model.email,
                });

                if (model.consent) {
                    this.trackingService.trackEmailSignup(model.email, action, 'permissions');
                }

                return true;
            })
        );
    }

    public submitOffer(model: IOfferDto, modelName: string): Observable<boolean> {
        return this.http.post<boolean>('/api/offer', model).pipe(
            map(() => {
                const action = model.isUsed ? 'offer used car' : 'offer';
                this.trackingService.trackFormSubmission({
                    eventCategory: 'get offer',
                    eventAction: action,
                    eventLabel: modelName,
                    email: model.email,
                });
                if (model.consent) {
                    this.trackingService.trackEmailSignup(model.email, action, 'permissions');
                }
                return true;
            })
        );
    }

    public submitConfiguration(model: IConfigurationDto): Observable<string> {
        return this.http.post<string>(`/api/configuration`, model).pipe(
            map((val) => {
                const isToCustomer = model.context.sendToCustomerOnly;
                const eventLabel = isToCustomer ? 'send to me' : 'send to dealer';
                const eventAction = isToCustomer ? 'print order' : 'conversion';
                this.trackingService.TrackConfiguratorSubmit(eventAction, eventLabel, model.person.attributes.email, model.context.model);
                if (model.consent) {
                    this.trackingService.trackEmailSignup(model.person.attributes.email, eventLabel);
                }
                return val;
            })
        );
    }
}
