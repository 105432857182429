import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IMegaMenuVisibilitySettings, INavigationResponse } from '@ncg/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureDetectionService } from '../../core/feature-detection.service';

import { menuWrapperAnimation } from '../../utils/animations/mega-menu.animations';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'ncg-main-menu',
    template: `
        <nav class="main-menu navbar" aria-label="main navigation" (mouseleave)="clearActiveItem()">
            <div class="navbar-menu">
                <div class="navbar-start" role="menubar">
                    <ng-container *ngFor="let item of menuItems; trackBy: trackByMethod">
                        <div *ngIf="item && item.includeInNavigation && !item.hideInMenu">
                            <ng-container *ngIf="item?.pageLink?.isExternal; else internalLink">
                                <a
                                    #menuitem
                                    role="menuitem"
                                    [attr.aria-haspopup]="!item.hideChildrenOnDesktop && !!item.children?.length"
                                    [attr.aria-expanded]="activeItem?.id === item.id && isOpen"
                                    routerLinkActive="is-active"
                                    [ngClass]="{
                                        'is-active': isActive(item) || item.id === activeItem?.id,
                                        'is-current': isActive(item)
                                    }"
                                    (mouseenter)="setActiveItem(item, dropElement)"
                                    (mouseleave)="onMouseLeave()"
                                    rel="noopener"
                                    [href]="item?.pageLink?.url"
                                    [attr.target]="item?.pageLink?.target"
                                    class="main-menu__item navbar-item"
                                    tabindex="-1"
                                >
                                    <span
                                        >{{ item.name }}
                                        <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                    </span>
                                </a>
                            </ng-container>

                            <ng-template #internalLink>
                                <a
                                    #menuitem
                                    role="menuitem"
                                    [attr.aria-haspopup]="!item.hideChildrenOnDesktop && !!item.children?.length"
                                    [attr.aria-expanded]="activeItem?.id === item.id && isOpen"
                                    routerLinkActive="is-active"
                                    [ngClass]="{
                                        'is-active': isActive(item) || item.id === activeItem?.id,
                                        'is-current': isActive(item)
                                    }"
                                    (mouseenter)="setActiveItem(item, dropElement)"
                                    (mouseleave)="onMouseLeave()"
                                    [routerLink]="[item?.pageLink?.url ? item?.pageLink?.url : item.url]"
                                    class="main-menu__item navbar-item"
                                    tabindex="-1"
                                >
                                    <span
                                        >{{ item.name }}
                                        <span aria-hidden="true" class="navbar-item__active-bar"></span>
                                    </span>
                                </a>
                            </ng-template>
                            <div
                                class="mega-menu__wrapper"
                                [ngClass]="{ 'is-open': activeItem?.id === item.id && isOpen }"
                                #dropElement
                                [@menuAnimation]="{
                                    value: activeItem?.id === item.id ? animationState : 'hidden',
                                    params: {
                                        prevDropdownHeight: prevDropdownHeight + 'px',
                                        currentDropdownHeight: currentDropdownHeight + 'px'
                                    }
                                }"
                            >
                                <button
                                    type="button"
                                    class="button is-text is-narrow mega-menu__close"
                                    *ngIf="isTouchDevice"
                                    (click)="clearActiveItem()"
                                    aria-label="close dropdown menu"
                                >
                                    <svg-icon-sprite
                                        [src]="'cross-light'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        classes=""
                                    ></svg-icon-sprite>
                                </button>
                                <ng-container *ngIf="!item.hideChildrenOnDesktop && item.children?.length">
                                    <ncg-mega-models-menu
                                        *ngIf="!item.megaMenu && item.template === 'modelOverviewPage'"
                                        [children]="item.children"
                                        [allModelsUrl]="item.url"
                                        [isOpen]="isOpen"
                                    ></ncg-mega-models-menu>
                                    <ncg-mega-menu *ngIf="!item.megaMenu && item.template !== 'modelOverviewPage'" [item]="item"></ncg-mega-menu>
                                    <ncg-mega-menu-alternate *ngIf="item.megaMenu" [item]="item"></ncg-mega-menu-alternate>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </nav>
    `,
    animations: [menuWrapperAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() isFirstLevelMenu = true;
    @Output() isMegaMenuVisibleSettings: EventEmitter<IMegaMenuVisibilitySettings> = new EventEmitter();
    hoverDelayTimer = 0;
    menuItems: INavigationResponse[] = [];
    activeItem: INavigationResponse | undefined;
    prevDropdownHeight = 0;
    currentDropdownHeight = 0;
    animationState: 'expand' | 'hidden' | undefined = undefined;
    isOpen = false;
    isTouchDevice = false;
    changeRefs: ElementRef[];

    private keyEventListeners: ((event: KeyboardEvent) => void)[] = [];
    private readonly unsubscribe = new Subject<void>();

    @ViewChild('dropElement') dropElement: ElementRef;
    @ViewChildren('menuitem') menuitemRefs: QueryList<ElementRef>;

    constructor(
        private readonly router: Router,
        private readonly navigationService: NavigationService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isTouchDevice = this.featureDetectionService.isTouchDevice();

        this.getMenu();

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.clearActiveItem();
            }
        });
    }

    ngAfterViewInit() {
        // keyboard navigation
        this.menuitemRefs.changes.pipe(takeUntil(this.unsubscribe)).subscribe((refs) => {
            // Cleanup old event listeners before adding new ones
            this.cleanupEventListeners();

            this.changeRefs = refs.toArray();
            // capture focus on first item
            refs.first.nativeElement.tabIndex = 0;

            // add event listeners in loop to keep track of prev/next items and to control restoring focus
            this.keyEventListeners = this.changeRefs?.map((item, index) => {
                const handler = (event: KeyboardEvent) => this.keyEventHandler(event, index, item);
                item.nativeElement.addEventListener('keydown', handler);
                return handler; // Store handler reference
            });

            this.cd.markForCheck();
        });
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const { key } = event;

        switch (key) {
            case 'Esc':
            case 'Escape':
                if (this.isOpen) {
                    this.clearActiveItem();
                }
                break;
        }
    }

    private getMenu() {
        if (this.isFirstLevelMenu) {
            this.navigationService
                .getNavigation()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((menu) => {
                    this.menuItems = menu;
                    this.cd.markForCheck();
                });
        } else {
            this.navigationService
                .getNavigationById()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((menu) => {
                    this.menuItems = menu;
                    this.cd.markForCheck();
                });
        }
    }

    private keyEventHandler(event: KeyboardEvent, index: number, item: ElementRef) {
        const { key } = event;

        switch (key) {
            case 'Left':
            case 'ArrowLeft':
                if (this.changeRefs[index - 1]) {
                    this.changeRefs[index - 1].nativeElement.focus();
                    // move tabIndex
                    this.changeRefs[index - 1].nativeElement.tabIndex = 0;
                    item.nativeElement.tabIndex = -1;
                }
                break;
            case 'Right':
            case 'ArrowRight':
                if (this.changeRefs[index + 1]) {
                    this.changeRefs[index + 1].nativeElement.focus();
                    // move tabIndex
                    this.changeRefs[index + 1].nativeElement.tabIndex = 0;
                    item.nativeElement.tabIndex = -1;
                }
                break;
            case ' ':
                // prevent scrolling
                event.preventDefault();
                // toggle menu
                if (this.isOpen) {
                    this.clearActiveItem();
                } else {
                    this.setActiveItem(this.menuItems[index], this.dropElement.nativeElement);
                }
                break;
            case 'Down':
            case 'ArrowDown':
                // prevent scrolling
                event.preventDefault();
                // open menu
                this.setActiveItem(this.menuItems[index], this.dropElement.nativeElement);
                break;
            case 'Up':
            case 'ArrowUp':
                // close menu
                if (this.isOpen) {
                    this.clearActiveItem();
                }
                break;
        }
    }

    private cleanupEventListeners(): void {
        this.changeRefs?.forEach((item, index) => {
            item.nativeElement.removeEventListener('keydown', this.keyEventListeners[index]);
        });
        this.keyEventListeners = []; // Clear stored handlers
    }

    setActiveItem(item: INavigationResponse, dropdownElement: HTMLElement) {
        // If user hover same menu link, don't do anything
        if (this.activeItem && this.activeItem.children?.length && this.activeItem.id === item.id) {
            return;
        }

        //  If item has `hideChildrenOnDesktop` set to true
        //  OR
        //  Item dons't have any children
        //  clear active menu and close dropdown
        if (item.hideChildrenOnDesktop || !item.children?.length) {
            this.clearActiveItem();
            this.animationState = 'hidden';
            return;
        }

        this.prevDropdownHeight = this.currentDropdownHeight;

        /**
         * Delay dropdown animation if user switch betweens menu items fast.
         */
        window.clearTimeout(this.hoverDelayTimer);
        this.hoverDelayTimer = window.setTimeout(() => {
            this.activeItem = item;
            this.isOpen = true;
            this.currentDropdownHeight = dropdownElement.offsetHeight;
            this.isMegaMenuVisibleSettings.emit({ isVisible: this.isOpen });
            this.animationState = 'expand';
            this.cd.detectChanges();
        }, 200);
    }

    clearActiveItem() {
        if (this.featureDetectionService.isBrowser()) {
            window.clearTimeout(this.hoverDelayTimer);
            this.activeItem = undefined;
            this.prevDropdownHeight = 0;
            this.currentDropdownHeight = 0;
            this.isOpen = false;
            this.isMegaMenuVisibleSettings.emit({ isVisible: this.isOpen });
            this.animationState = 'hidden';
            this.cd.detectChanges();
        }
    }

    isActive(item: INavigationResponse): boolean {
        return this.router.isActive(item.url, false);
    }

    onMouseLeave() {
        window.clearTimeout(this.hoverDelayTimer);
    }

    ngOnDestroy(): void {
        // cleanup eventlisteners
        this.cleanupEventListeners();

        this.clearActiveItem();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }
}
