import { AnimationEvent } from '@angular/animations';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Params, Router } from '@angular/router';
import { getBuildIdQuery, ICarSearchResult, ILink, ISearchNode, ISearchResult } from '@ncg/data';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { TrackingService } from '../core/tracking.service';

import { FormService } from '../form/form.service';
import { ImpactOverlayRef } from '../overlay/overlay-ref';
import { ImpactOverlayLeave } from '../overlay/overlay.interfaces';
import { IMPACT_OVERLAY_DATA } from '../overlay/overlay.tokens';
import { slideInLeft } from '../utils/animations/slide.animations';
import { SearchService } from './search.service';

export enum KEY_NAME {
    ESCAPE = 'Escape',
    ENTER = 'Enter',
    DOWN = 'ArrowDown',
    UP = 'ArrowUp',
}

@Component({
    selector: 'ncg-search',
    template: `
        <aside
            [id]="dialogRef?.id"
            class="search"
            role="search"
            [@slideContent]="animationState"
            (@slideContent.start)="onAnimationStart($event)"
            (@slideContent.done)="onAnimationDone($event)"
        >
            <button aria-label="Closes search dialog" class="button-icon search__close" (click)="onClose()">
                <svg-icon-sprite
                    *ngIf="featureDetectionService.isBrowser()"
                    [src]="'assets/images/sprite-multi.svg' + buildIdQuery + '#close-circle'"
                    [width]="'58px'"
                    [height]="'58px'"
                    aria-hidden="true"
                    class="is-flex"
                ></svg-icon-sprite>
            </button>

            <div class="search__content" *ngIf="form">
                <form
                    [formGroup]="form"
                    (submit)="onEnterSearch()"
                    action="."
                    class="search__form"
                    role="search"
                    aria-label="Search for content and models"
                >
                    <div class="field">
                        <ncg-spinner [active]="isSearching" [isSearch]="true"></ncg-spinner>
                        <div class="control control-search has-icons-right">
                            <input
                                #searchRef
                                class="input"
                                formControlName="search"
                                name="search"
                                role="searchbox"
                                type="search"
                                [placeholder]="'search.placeholder' | translate"
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                required
                            />
                            <span class="icon is-right is-clickable">
                                <span (click)="onClearSearch()" *ngIf="searchField?.value">
                                    <img [src]="'assets/icons/search-close.svg' + buildIdQuery" alt="clear search" />
                                </span>
                                <ng-container *ngIf="!searchField?.value">
                                    <img [src]="'assets/icons/search.svg' + buildIdQuery" alt="search icon" />
                                </ng-container>
                            </span>
                        </div>
                    </div>
                </form>

                <div class="search-empty" *ngIf="!isSearching && !hasResult && !initialSearch">
                    <div class="search-empty__heading">
                        {{ 'search.no_results' | translate: { query: searchValue } }}
                    </div>
                    <div class="search-empty__helper">
                        {{ 'search.no_results_helper_text' | translate }}
                    </div>
                </div>

                <div class="search-sections">
                    <ng-container *ngFor="let section of usedCarResults">
                        <ncg-search-section-used
                            class="search-sections__item"
                            *ngIf="section.results?.length"
                            [currentItem]="currentItem"
                            [results]="section.results"
                            [total]="section.total"
                            [count]="section.count"
                            [make]="section.make"
                            [query]="searchValue"
                        ></ncg-search-section-used>
                    </ng-container>

                    <ncg-search-section
                        *ngIf="modelResults.length"
                        class="search-sections__item"
                        [currentItem]="currentItem"
                        [results]="modelResults"
                        [isModel]="true"
                        [title]="'search.models' | translate"
                    ></ncg-search-section>

                    <ncg-search-section
                        *ngIf="campaignResults.length"
                        class="search-sections__item"
                        [currentItem]="currentItem"
                        [results]="campaignResults"
                        [isModel]="false"
                        [title]="'search.campaigns' | translate"
                    ></ncg-search-section>

                    <ncg-search-section
                        *ngIf="pageResults?.length"
                        class="search-sections__item"
                        [currentItem]="currentItem"
                        [results]="pageResults"
                        [isModel]="false"
                        [title]="'search.pages' | translate"
                    ></ncg-search-section>

                    <div *ngIf="quicklinksSearch?.length" class="search-sections__item">
                        <div class="search-section" *ngIf="!hasResult || searchField!.value?.length === 0">
                            <div class="search-section__header is-quicklinks">
                                <h5 class="search-section__header--title">{{ 'search.links' | translate }}</h5>
                            </div>
                            <div class="columns is-gapless">
                                <nav class="search-menu">
                                    <ul class="menu-list" role="navigation">
                                        <li class="search-menu__item" *ngFor="let link of quicklinksSearch">
                                            <a [routerLink]="[link.url]">{{ link.name }}</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    `,
    animations: [slideInLeft],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit, ImpactOverlayLeave {
    form?: UntypedFormGroup;
    @ViewChild('searchRef') searchRef: ElementRef;
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    animationStateChanged = new EventEmitter<AnimationEvent>();
    modelResults: readonly ISearchNode[] | [] = [];
    campaignResults: readonly ISearchNode[] | [] = [];
    pageResults: readonly ISearchNode[] | [] = [];
    usedCarResults: readonly ICarSearchResult[];
    totalResults: any[] = [];
    hasResult = false;
    currentIndex = -1;
    currentItem?: string;
    quicklinksSearch?: ILink[];
    searchValue = '';
    isSearching = false;
    searchTerm$ = new Subject<string>();
    initialSearch = true;
    buildIdQuery = getBuildIdQuery();

    private readonly unsubscribe = new Subject<void>();
    private currentParams: Params = {};

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const x = event.key;

        if (x === KEY_NAME.ESCAPE) {
            this.onClose();
        }

        if (x === KEY_NAME.UP) {
            this.navigateUp();
        }

        if (x === KEY_NAME.DOWN) {
            this.navigateDown();
        }

        if (x === KEY_NAME.ENTER) {
            if (this.currentItem) {
                this.onClose();
                this.router.navigateByUrl(this.currentItem);
            }
        }
    }

    constructor(
        public dialogRef: ImpactOverlayRef,
        @Inject(IMPACT_OVERLAY_DATA) public data: any,
        private trackingService: TrackingService,
        private cd: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private searchService: SearchService,
        private router: Router,
        private location: Location,
        public featureDetectionService: FeatureDetectionService
    ) {}

    ngOnInit() {
        this.currentParams = this.getQueryParams();

        this.router.events.pipe(take(1), takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.dialogRef?.componentInstance) {
                this.onClose();
            }
        });

        this.dialogRef
            .beforeClose()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.updateParams('', true);
            });

        this.createForm();
        this.listenForSearch();

        if (this.currentParams.search && this.searchField) {
            this.searchField.patchValue(this.currentParams.search);
        }

        if (this.data && this.data.quicklinksSearch) {
            this.quicklinksSearch = this.data.quicklinksSearch;
        }

        this.searchTerm$
            .pipe(
                distinctUntilChanged(),
                switchMap((value) => this.search(value)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.isSearching = false;
                this.cd.markForCheck();
            });
    }

    private getQueryParams(): Params {
        return this.router.parseUrl(this.location.path()).queryParams;
    }

    ngAfterViewInit() {
        this.setFocus();
    }

    navigateUp() {
        if (this.hasResult) {
            this.currentIndex = this.currentIndex <= 0 ? (this.currentIndex = this.totalResults.length - 1) : this.currentIndex--;
            this.currentItem = this.totalResults[this.currentIndex].url || this.totalResults[this.currentIndex].id;
        }
    }

    navigateDown() {
        if (this.hasResult) {
            this.currentIndex = this.currentIndex >= this.totalResults.length - 1 ? (this.currentIndex = 0) : this.currentIndex++;
            this.currentItem = this.totalResults[this.currentIndex].url || this.totalResults[this.currentIndex].id;
        }
    }

    private updateParams(searchText: string, clearSearchParam = false) {
        const params = { ...this.currentParams };

        if (clearSearchParam) {
            delete params.search;
        } else {
            params.search = searchText;
        }

        this.location.replaceState(
            this.router.serializeUrl(
                this.router.createUrlTree([], {
                    queryParams: params,
                })
            ),
            undefined,
            this.location.getState()
        );
    }

    private setFocus() {
        if (this.searchRef && this.searchRef.nativeElement) {
            const searchInput = this.searchRef.nativeElement as HTMLInputElement;
            searchInput.focus();
        }
    }

    private createForm() {
        this.form = this.fb.group({
            search: ['', [Validators.minLength(1), Validators.required]],
        });
    }

    private listenForSearch() {
        if (this.searchField) {
            this.searchField.valueChanges.pipe(debounceTime(400), takeUntil(this.unsubscribe)).subscribe((searchValue: string) => {
                if (searchValue.length > 2) {
                    this.searchTerm$.next(searchValue);
                }
            });
        }
    }

    onEnterSearch() {
        if (this.searchField && this.searchField.value) {
            this.searchTerm$.next(this.searchField.value);
        }
    }

    search(searchText: string): Observable<ISearchResult> {
        this.isSearching = true;
        this.modelResults = [];
        this.campaignResults = [];
        this.pageResults = [];
        this.usedCarResults = [];
        this.cd.markForCheck();

        return this.searchService.search(searchText).pipe(
            tap((result) => {
                if (result) {
                    this.searchValue = searchText;
                    this.totalResults = [];
                    this.pageResults = result.content;
                    this.campaignResults = result.campaigns;
                    this.modelResults = result.models;
                    this.usedCarResults = result.usedCars;
                    this.totalResults.push(...this.modelResults);
                    this.usedCarResults.forEach((searchResult: ICarSearchResult) => {
                        this.totalResults.push(...searchResult.results);
                    });
                    this.totalResults.push(...this.campaignResults);
                    this.totalResults.push(...this.pageResults);
                } else {
                    this.clearResults();
                }

                this.hasResult = this.totalResults.length ? true : false;
                this.currentIndex = -1;

                this.updateParams(searchText);
                this.initialSearch = false;
                this.cd.markForCheck();
            }),
            debounceTime(1000),
            tap(() => this.trackSearch(searchText)),
            catchError((error) => {
                this.isSearching = false;
                this.initialSearch = false;
                this.cd.markForCheck();
                return of(error);
            })
        );
    }

    private trackSearch(searchTerm: string = '') {
        if (this.hasResult) {
            this.trackingService.trackSearch(searchTerm, 'results');
        } else {
            this.trackingService.trackSearch(searchTerm, 'no results');
        }
    }

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
        this.cd.detectChanges();
    }

    onClose() {
        this.dialogRef.close();
    }

    onClearSearch() {
        if (this.searchField) {
            this.searchField.patchValue('');
            this.searchField.markAsUntouched();
            this.searchField.markAsPending();
        }

        this.clearResults();
    }

    clearResults() {
        this.searchValue = '';
        this.modelResults = [];
        this.campaignResults = [];
        this.pageResults = [];
        this.usedCarResults = [];
        this.totalResults = [];
        this.cd.markForCheck();
    }

    get searchField(): AbstractControl | null {
        return this.form && this.form.get('search') ? this.form.get('search') : null;
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
