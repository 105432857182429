import { Injectable, OnDestroy } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { FeatureDetectionService } from '../core/feature-detection.service';
import { ScriptLoadService } from '../core/script-load.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { AutoProffSettings } from './auto-proff.models';

@Injectable({
    providedIn: 'root',
})
export class AutoProffService implements OnDestroy {
    private readonly autoProffScriptUrl = 'https://webkit.autoproff.com/js/widget.js';
    private readonly widgetLoaded$ = new ReplaySubject<boolean>(1);

    constructor(
        private readonly scriptLoadService: ScriptLoadService,
        private readonly settingsService: SettingsService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly trackingService: TrackingService
    ) {}

    public async init({ embedSelector = '' }: { embedSelector?: string } = {}): Promise<boolean> {
        if (!this.featureDetectionService.isBrowser()) {
            console.error('Do not load AutoProff on server!');
            return false;
        }
        const { autoProff, seoLanguage } = await firstValueFrom(this.settingsService.settings$);
        if (!(autoProff.ID && autoProff.settingGroup)) {
            console.warn('No AutoProff ID or Group');
            return false;
        }
        const scriptUrl = `${this.autoProffScriptUrl}?v=${autoProff.ID}`;

        let style;
        try {
            style = JSON.parse(autoProff.settingStyle || '');
        } catch {
            console.warn("'Style' is not valid JSON");
        }
        let text;
        try {
            text = JSON.parse(autoProff.settingText || '');
        } catch {
            console.warn("'Text' is not valid JSON");
        }
        const isScriptInserted = this.scriptLoadService.isScriptInserted(scriptUrl);
        const settings: AutoProffSettings = {
            group: autoProff.settingGroup,
            style,
            motor: 'dmr',
            button: autoProff.settingButton,
            button_text: autoProff.settingButtonText,
            autoopen: autoProff.settingAutoOpen,
            locale: seoLanguage,
            branding: false,
            integrated_button_selector: '.ap-cta',
            embed_selector: embedSelector,
            marketing_consent: true,
            button_position_vertical: 'bottom',
            button_position_horizontal: 'left',
            text,
            reinitialize: isScriptInserted,
        };
        const callback = () => {
            if (window.autoproff) {
                // Initialize AutoProff with callee specific settings. The eventhandler will resolve promise, for subsequent invocations.
                window.autoproff(settings);
            } else {
                console.warn('AutoProff does not exist on window. Something must have gone wrong.');
            }
        };

        if (isScriptInserted) {
            await firstValueFrom(this.widgetLoaded$);
            callback();
        } else {
            window.addEventListener('message', this.messageHandler, false);
            this.scriptLoadService.loadScript(scriptUrl, { callback });
        }
        return firstValueFrom(this.widgetLoaded$);
    }

    public openPopup(): boolean {
        if (!this.featureDetectionService.isBrowser()) {
            return false;
        }
        if (window.autoproff?.openPopup) {
            window.autoproff.openPopup();
            return true;
        }
        return false;
    }

    public ngOnDestroy(): void {
        if (this.featureDetectionService.isBrowser()) {
            window.removeEventListener('message', this.messageHandler);
        }
    }

    private readonly messageHandler = ({ data }: MessageEvent<AutoProfMessage>): void => {
        if (!data) {
            return;
        }

        const { event, messageType, frameHeight, whoAmI, eventData } = data;

        switch (event) {
            case 'widget-loaded':
                this.widgetLoaded$.next(true);
                break;
            case 'car-submit':
                this.trackingService.trackAutoProff('registration_number_type_in', eventData?.email);
                break;
            case 'customer-submit':
                this.trackingService.trackAutoProff('tradeincar_complete', eventData?.email);
                break;
            default:
                break;
        }
        // so bad, but this is how we figure out that the iframe is closed again, so we can reset actionBarConversionInProgress 🤷
        if (
            this.trackingService.actionBarConversionInProgress === 'trade_in_car' &&
            messageType === 'resizeIframe' &&
            frameHeight === 0 &&
            whoAmI === 'popup'
        ) {
            this.trackingService.actionBarConversionInProgress = undefined;
        }
    };
}

declare global {
    interface Window {
        autoproff?: ((settings: AutoProffSettings) => void) & {
            openPopup: () => void;
        };
    }
}

interface AutoProfMessage {
    event?:
        | 'widget-loaded'
        | 'car-submit'
        | 'vehicle-yours-yes'
        | 'vehicle-yours-no'
        | 'show-all-variants'
        | 'equipment-variants-submit'
        | 'mileage-servicebook-submit'
        | 'customer-submit'
        | 'new-valuation'
        | 'ready-to-sell'
        | 'data-enrichment-image-uploaded-later'
        | 'data-enrichment-image'
        | 'enrichment-image'
        | 'enrichment-image-submit'
        | 'start-dat-searchtree'
        | 'data-enrichment-equipment'
        | 'data-enrichment-comment'
        | 'survey-submit'
        | 'thank-you';
    messageType?: 'resizeIframe';
    frameHeight?: number;
    whoAmI?: 'popup' | 'embed';
    eventData?: {
        email?: string;
    };
}
