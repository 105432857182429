import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';
import { firstValueFrom, map } from 'rxjs';
import { SettingsService } from '../core/settings.service';

@Component({
    selector: 'ncg-logos',
    template: `
        <ng-container [ngSwitch]="lowerCaseKey">
            <!--BMW & Mini logos - Always allow these logos on BAG or only if allowAllLogos is true i.e. not on the used car page and related modules on Terminalen-->
            <ng-container *ngIf="isBag || allowAllLogos">
                <img
                    *ngSwitchCase="'bmw'"
                    [src]="'assets/images/logos/bmw.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'mini'"
                    [src]="'assets/images/logos/mini.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthXl"
                    [alt]="defaultText"
                />
            </ng-container>
            <!--Other logos - Only show if allowAllLogos is true i.e. not on the used car page and related modules regardless of site-->
            <ng-container *ngIf="allowAllLogos">
                <img
                    *ngSwitchCase="'hyundai'"
                    [src]="'assets/images/logos/hyundai.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'nissan'"
                    [src]="'assets/images/logos/nissan.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'mazda'"
                    [src]="'assets/images/logos/mazda.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'fiat'"
                    [src]="'assets/images/logos/fiat.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'fiat_professional'"
                    [src]="'assets/images/logos/fiat_professional.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'suzuki'"
                    [src]="'assets/images/logos/suzuki.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'honda'"
                    [src]="'assets/images/logos/honda.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'toyota'"
                    [src]="'assets/images/logos/toyota.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'landrover'"
                    [src]="'assets/images/logos/landrover.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'jaguar'"
                    [src]="'assets/images/logos/jaguar.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'vw'"
                    [src]="'assets/images/logos/volkswagen.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'opel'"
                    [src]="'assets/images/logos/opel.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'peugeot'"
                    [src]="'assets/images/logos/peugeot.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthSm"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'citroen'"
                    [src]="'assets/images/logos/citroen.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'citroën'"
                    [src]="'assets/images/logos/citroen.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'mercedes'"
                    [src]="'assets/images/logos/mercedes.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'renault'"
                    [src]="'assets/images/logos/renault.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthSm"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'volvo'"
                    [src]="'assets/images/logos/volvo.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'alfaromeo'"
                    [src]="'assets/images/logos/alfa-romeo.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'audi'"
                    [src]="'assets/images/logos/audi.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'ford'"
                    [src]="'assets/images/logos/ford.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'kia'"
                    [src]="'assets/images/logos/kia.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthLg"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'skoda'"
                    [src]="'assets/images/logos/skoda.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'dacia'"
                    [src]="'assets/images/logos/dacia.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'mitsubishi'"
                    [src]="'assets/images/logos/mitsubishi.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'jeep'"
                    [src]="'assets/images/logos/jeep.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'seat'"
                    [src]="'assets/images/logos/seat.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'byd'"
                    [src]="'assets/images/logos/byd.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidthXl"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'cupra'"
                    [src]="'assets/images/logos/cupra.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'tesla'"
                    [src]="'assets/images/logos/tesla.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'mg'"
                    [src]="'assets/images/logos/mg.svg' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
                <img
                    *ngSwitchCase="'maxus'"
                    [src]="'assets/images/logos/maxus.png' + buildIdQuery"
                    [className]="imageClass"
                    [width]="iconWidth"
                    [alt]="defaultText"
                />
            </ng-container>
            <!--Fallback text if no logo could be found in switch-->
            <ng-container *ngIf="allowAllLogos || isBag || useDefaultText">
                <ng-container *ngSwitchDefault>{{ defaultText }}</ng-container>
            </ng-container>
        </ng-container>
        <span class="tabs__amount" *ngIf="count || count === 0">{{ count }}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogosComponent implements OnInit {
    public readonly buildIdQuery = getBuildIdQuery();

    public defaultText = '';
    public isBag = false;
    public lowerCaseKey = '';

    @Input()
    public count?: number;

    @Input()
    public iconWidthSm = '32';

    @Input()
    public iconWidth = '38';

    @Input()
    public iconWidthLg = '48';

    @Input()
    public iconWidthXl = '64';

    @Input()
    public imageClass = '';

    @Input()
    public key: string | undefined | null = '';

    @Input()
    public allowAllLogos = false;

    @Input()
    public useDefaultText = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly settingsService: SettingsService
    ) {}

    public async ngOnInit() {
        this.isBag = (await firstValueFrom(this.settingsService.get().pipe(map((s) => s.brandId)))) === 'bag';
        this.key = this.key ?? '';
        this.defaultText = this.key;
        this.lowerCaseKey = this.key.toLowerCase().replaceAll(' ', '');
        this.cd.markForCheck();
    }
}
