import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { expansionAnimation } from './expansion.animation';

@Component({
    selector: 'ncg-expansion-panel',
    template: `
        <div
            #header
            [id]="'button-' + id"
            class="expansion-panel__header level is-mobile"
            [ngClass]="{ 'is-clickable': hasContent && !forceStatic }"
            [attr.tabindex]="hasContent && !forceStatic ? '0' : null"
            [attr.role]="hasContent && !forceStatic ? 'button' : null"
            [attr.aria-expanded]="hasContent && !forceStatic ? expanded : null"
            (click)="hasContent && !forceStatic ? onExpandClick($event) : undefined"
            (keypress)="hasContent && !forceStatic ? onExpandKeypress($event) : undefined"
        >
            <ng-content select="[expansion-title]"></ng-content>
            <div class="expansion-panel__icon" *ngIf="hasContent && !forceStatic">
                <div class="is-flex chevron chevron--animate" aria-hidden="true" [ngClass]="{ 'chevron--up': expanded }">
                    <ng-content select="[expansion-icon]"></ng-content>
                </div>
            </div>
        </div>
        <section
            #content
            [id]="'panel-' + id"
            class="expansion-panel__content"
            [attr.aria-hidden]="hasContent && !forceStatic ? (expanded ? 'false' : 'true') : undefined"
            [attr.aria-labelledby]="hasContent && !forceStatic ? 'button-' + id : undefined"
            [@expandAnimation]="forceStatic ? 'expanded' : expandedState"
            [attr.inert]="expanded || forceStatic ? undefined : true"
        >
            <ng-content></ng-content>
        </section>
    `,
    animations: [expansionAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements OnInit, AfterViewInit {
    @ViewChild('content') public contentRef: ElementRef<HTMLElement>;
    @ViewChild('header') public headerRef: ElementRef<HTMLButtonElement>;

    @Input() expanded = false;
    @Input() forceStatic = false;

    public readonly id = this.randomId();
    public expandedState?: 'expanded' | 'closed';
    public hasContent: boolean;

    constructor(private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.expandedState = this.expanded ? 'expanded' : 'closed';
    }

    onExpandKeypress(event: KeyboardEvent) {
        switch (event.key) {
            case ' ':
            case 'Enter':
                this.onExpandClick(event);
                break;
        }
    }

    onExpandClick(event?: Event) {
        this.expanded = !this.expanded;
        this.expandedState = this.expanded ? 'expanded' : 'closed';

        if (event) {
            // Prevent double-click
            event.stopPropagation();
            event.preventDefault();
        }
    }

    ngAfterViewInit(): void {
        const label = this.headerRef.nativeElement.childNodes[0].textContent;
        if (label) {
            this.headerRef.nativeElement.setAttribute('aria-label', label);
        }

        this.hasContent = Boolean(this.contentRef.nativeElement?.children.length);
        this.cd.detectChanges();
    }

    private randomId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }
}
