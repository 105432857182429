import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { getBuildIdQuery } from '@ncg/data';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ScriptLoadService } from './script-load.service';
import { SettingsService } from './settings.service';
import { TrackingService } from './tracking.service';

@Injectable({
    providedIn: 'root',
})
export class BloomreachService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly scriptLoad: ScriptLoadService,
        private readonly settings: SettingsService,
        private readonly trackingService: TrackingService,
        @Inject(DOCUMENT) private readonly doc: Document,
        @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public async initBloomreach() {
        const { bloomreachToken } = await firstValueFrom(this.settings.get());
        const isServer = isPlatformServer(this.platformId);
        const scriptPath = `assets/scripts/bloomreach.js${getBuildIdQuery()}`;

        if (isServer) {
            if (!bloomreachToken) {
                return;
            }

            // Add token to html for script.
            this.scriptLoad.loadScript(
                undefined,
                {
                    textContent: `
                    window.NIC = {
                        bloomreach: {
                            token: '${bloomreachToken}'
                        }
                    };`,
                },
                true
            );

            // Add script to html.
            this.scriptLoad.loadScript(scriptPath, { async: false }, true);
        } else if (this.scriptLoad.isScriptInserted(scriptPath) && !window.NIC?.bloomreach?.started) {
            this.trackingService
                .marketingConsentGiven()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((hasConsent) => {
                    if (hasConsent && window.exponea && window.NIC?.bloomreach && !window.NIC.bloomreach.started) {
                        window.exponea.start();
                        window.NIC.bloomreach.started = true;
                        this.unsubscribe.next();
                    }
                });
        }
    }
}

declare global {
    interface Window {
        exponea?: {
            start: () => void;
        };
        NIC?: {
            bloomreach: {
                token?: string;
                hasConsent: () => boolean;
                started?: boolean;
            };
        };
    }
}
