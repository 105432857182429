import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { setTabState } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { ListItemDirective } from '../../../list-item/list-item.directive';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { DialogService } from '../../../utils/dialog/dialog.service';
import { UtilsModule } from '../../../utils/utils.module';
import { ConfiguratorImageComponent } from '../../components';
import { ConfiguratorTabStates } from '../../configurator';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { ExteriorColorByVariantPipe } from '../../utils/exterior-color-by-variant.pipe';
import { GetPriceDifferencePipe } from '../../utils/get-price-difference.pipe';
import { KeyByIdPipe } from '../../utils/key-by-id.pipe';

@Component({
    selector: 'ncg-configurator-capacity',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <ng-container *ngIf="configuratorFacade.model$ | async as model">
                <div class="container" *ngIf="configuratorFacade.engineType$ | async as engineType" [@listAnimation]="engineType.capacities?.length">
                    <div class="configurator__cards">
                        <ng-container *ngFor="let capacity of engineType.capacities">
                            <ncg-card
                                class="configurator__card"
                                contentClass="configurator__card-content"
                                [selected]="capacity.id === params.capacity"
                            >
                                <ncg-configurator-image
                                    [fields]="capacity.resources?.[0]?.fields"
                                    sizes="300px"
                                    type="car"
                                    card-image
                                ></ncg-configurator-image>
                                <h2 class="title configurator__headline" *ngIf="configuratorFacade.tabStates$ | async as tabStates">
                                    <button
                                        class="configurator__font-weight--emphasis capacity-button configurator__link-cover"
                                        (click)="handleCapacityChange(tabStates, capacity.id)"
                                    >
                                        {{ model.title }} <br />
                                        <span class="capacity-name">{{ capacity.fields | dataById: 'CapacityMarketingTitle' }}</span>
                                    </button>
                                </h2>

                                <!--CapacityTotalAllowedWeight + CapacityTotalAllowedWeightUnit-->
                                <ng-container *ngIf="capacity.fields as fields">
                                    <ul class="configurator__list">
                                        <ng-container *ngIf="capacity.fields | dataById: 'CapacityTotalAllowedWeight' as value">
                                            <li
                                                ncgListItem
                                                *ngIf="capacity.fields | dataById: 'CapacityTotalAllowedWeightUnit' as unit"
                                                [innerHTML]="'configurator.capacity_total_weight' | translate: { value, unit } | safe: 'html'"
                                            >
                                                {{
                                                    'configurator.capacity_total_weight'
                                                        | translate
                                                            : {
                                                                  value,
                                                                  unit
                                                              }
                                                        | safe: 'html'
                                                }}
                                            </li>
                                        </ng-container>

                                        <!--CapacityTotalLength + CapacityTotalLengthUnit-->
                                        <ng-container *ngIf="capacity.fields | dataById: 'CapacityTotalLength' as value">
                                            <li
                                                ncgListItem
                                                *ngIf="capacity.fields | dataById: 'CapacityTotalLengthUnit' as unit"
                                                [innerHTML]="'configurator.capacity_total_length' | translate: { value, unit } | safe: 'html'"
                                            >
                                                {{
                                                    'configurator.capacity_total_length'
                                                        | translate
                                                            : {
                                                                  value,
                                                                  unit
                                                              }
                                                        | safe: 'html'
                                                }}
                                            </li>
                                        </ng-container>

                                        <!--CapacityTotalWidth + CapacityTotalWidthUnit-->
                                        <ng-container *ngIf="capacity.fields | dataById: 'CapacityTotalWidth' as value">
                                            <li
                                                ncgListItem
                                                *ngIf="capacity.fields | dataById: 'CapacityTotalWidthUnit' as unit"
                                                [innerHTML]="'configurator.capacity_total_width' | translate: { value, unit } | safe: 'html'"
                                            >
                                                {{
                                                    'configurator.capacity_total_width'
                                                        | translate
                                                            : {
                                                                  value,
                                                                  unit
                                                              }
                                                        | safe: 'html'
                                                }}
                                            </li>
                                        </ng-container>

                                        <!--CapacityTotalHeight + CapacityTotalHeightUnit-->
                                        <ng-container *ngIf="capacity.fields | dataById: 'CapacityTotalHeight' as value">
                                            <li
                                                ncgListItem
                                                *ngIf="capacity.fields | dataById: 'CapacityTotalHeightUnit' as unit"
                                                [innerHTML]="'configurator.capacity_total_height' | translate: { value, unit } | safe: 'html'"
                                            >
                                                {{
                                                    'configurator.capacity_total_height'
                                                        | translate
                                                            : {
                                                                  value,
                                                                  unit
                                                              }
                                                        | safe: 'html'
                                                }}
                                            </li>
                                        </ng-container>
                                    </ul>

                                    <!--CapacityInfoText-->
                                    <p
                                        class="configurator__text-tiny mt-5"
                                        *ngIf="capacity.fields | dataById: 'CapacityInfoText' as capacityInfoText"
                                    >
                                        {{ capacityInfoText }}
                                    </p>
                                </ng-container>
                            </ncg-card>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .capacity-button {
                font-size: inherit;
                font-family: inherit;
                background: transparent;
                cursor: pointer;
                text-align: left;
            }
            .capacity-name {
                display: inline-block;
                margin-top: 0.3rem;
                font-size: var(--font-size-base);
                color: var(--configurator-accent-color);
                font-family: var(--font-family-base);
            }
            .title {
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    margin: 1.2rem 0;
                    background-color: var(--color-grey-light);
                }
            }
        `,
    ],
    imports: [
        CommonModule,
        CardComponent,
        RouterModule,
        TranslateModule,
        DataByIdPipe,
        CurrencyFormatterPipe,
        AddCurrencyPipe,
        GetPriceDifferencePipe,
        ListItemDirective,
        KeyByIdPipe,
        ConfiguratorImageComponent,
        ExteriorColorByVariantPipe,
        UtilsModule,
    ],
})
export class ConfiguratorCapacityComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public readonly setTabState = setTabState;

    constructor(
        private readonly router: Router,
        private readonly dialogService: DialogService,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    public handleCapacityChange(tabStates: ConfiguratorTabStates, id?: string | null): void {
        const doCapacityChange = () => {
            this.router.navigate([], { queryParams: { capacity: id }, queryParamsHandling: 'merge', replaceUrl: true });
            this.configuratorFacade.dispatch(setTabState({ tab: 'capacity', tabState: 'visited' }));
        };

        const isStateDirty = Object.entries(tabStates)
            .filter(([tab]) => !['bodystyle', 'capacity'].includes(tab))
            .some(([, state]) => state !== 'pristine');

        if (!isStateDirty) {
            doCapacityChange();
            return;
        }

        this.dialogService
            .openDialog({
                data: {
                    header: 'configurator.tab_state_dialog_heading',
                    text: 'configurator.tab_state_dialog_body',
                    okBtn: 'configurator.tab_state_dialog_ok_button',
                    closeBtn: 'configurator.tab_state_dialog_close_button',
                },
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return;
                }
                dialogRef
                    .afterClose()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            doCapacityChange();
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
