import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getBuildIdQuery, ModelsListItemSimple } from '@ncg/data';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { randomId } from '../core/helpers';
import { FormService } from './form.service';

export const MODEL_QUERY_SELECTOR = 'selectedmodelid';

@Component({
    selector: 'ncg-models-select',
    template: `
        <div class="select-car custom-select" *ngIf="vehicle && models && models.length">
            <div class="field" [formGroup]="parentForm">
                <label [for]="inputId" class="label">{{ 'forms.model_select_label' | translate }}*</label>
                <div
                    class="control has-icons-left has-icons-right"
                    [class.has-icons-left]="vehicle?.value && vehicle?.value.image && vehicle?.value.image?.url"
                >
                    <input
                        data-testid="modelselect"
                        type="text"
                        [id]="inputId"
                        class="input"
                        [formControlName]="controlName"
                        [class.is-danger]="errors(controlName)"
                        [attr.aria-invalid]="errors(controlName)"
                        [attr.aria-errormessage]="errors(controlName) ? inputId + '_error' : null"
                        [autocomplete]="'off'"
                        [ngbTypeahead]="search"
                        [focusFirst]="true"
                        [resultTemplate]="modelListItemTmpl"
                        [inputFormatter]="formatSelectedInputValue"
                        (focus)="updateNext($event)"
                        (click)="updateNext($event)"
                        (selectItem)="onSelectedItem($event)"
                        (blur)="onBlur()"
                        #modelsTypeahead="ngbTypeahead"
                    />
                    <span class="icon is-left" aria-hidden="true" *ngIf="vehicle?.value && vehicle?.value.image && vehicle?.value.image?.url">
                        <div class="select-car__selected-image" [style.background-image]="'url(' + vehicle?.value?.image?.url + '?width=50)'"></div>
                    </span>

                    <span class="icon is-right" [ngClass]="{ 'is-clickable': modelsTypeahead.isPopupOpen() }" aria-hidden="true">
                        <span class="chevron chevron--animate" [ngClass]="{ 'chevron--up': modelsTypeahead.isPopupOpen() }">
                            <img [src]="'assets/icons/select-arrow.svg' + buildIdQuery" />
                        </span>
                    </span>

                    <p [id]="inputId + '_error'" class="help is-danger" *ngIf="vehicle?.errors && vehicle?.touched">
                        <ng-container *ngIf="vehicle?.hasError('invalidModel'); else otherErrorsTmpl">
                            {{ 'forms.error_invalid_model' | translate }}
                        </ng-container>
                        <ng-template #otherErrorsTmpl>{{ 'forms.error_required_field' | translate }}</ng-template>
                    </p>
                </div>

                <ng-template #modelListItemTmpl let-modelListItem="result" let-searchTerm="term">
                    <div class="select-car-list-item">
                        <div
                            class="select-car-list-item__image"
                            *ngIf="modelListItem.image && modelListItem.image.url"
                            [style.background-image]="'url(' + modelListItem.image.url + '?width=100)'"
                        ></div>
                        <div class="select-car-list-item__text">
                            <ngb-highlight [result]="modelListItem.name" [term]="searchTerm"></ngb-highlight>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelsSelectComponent implements OnInit, OnDestroy {
    @ViewChild('modelsTypeahead') modelsTypeahead: NgbTypeahead;
    @Input() models: ModelsListItemSimple[];
    @Input() parentForm: UntypedFormGroup;
    @Input() controlName = 'vehicle';
    @Input() isTouched?: boolean; // Used to trigger changeDetection. When user submits an invalid form
    selectedModel?: ModelsListItemSimple;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    inputId: string;
    buildIdQuery = getBuildIdQuery();

    private readonly unsubscribe = new Subject<void>();

    constructor(private route: ActivatedRoute) {}

    get vehicle() {
        return this.parentForm && this.parentForm.get(this.controlName) ? this.parentForm.get(this.controlName) : null;
    }

    ngOnInit() {
        this.inputId = randomId('typeahead-template');
        const id = this.route.snapshot.queryParamMap.get(MODEL_QUERY_SELECTOR);
        if (id) {
            this.setSelectedModelById(id);
        }
    }

    // Is actually InputEvent, which have the value property
    updateNext(event: any) {
        this.click$.next(event.target.value);
    }

    setSelectedModelById(id: string) {
        const modelFound = this.models.find((model) => model.pimId === id);

        if (modelFound) {
            this.vehicle?.setValue(modelFound);
            this.vehicle?.markAsTouched();
        }
    }

    search = (searchTerm$: Observable<string>) => {
        const debouncedText$ = searchTerm$.pipe(distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.modelsTypeahead.isPopupOpen()));

        return merge(debouncedText$, this.focus$, clicksWithClosedPopup$).pipe(
            map((term) => {
                if (this.vehicle && this.vehicle.value) {
                    term = '';
                    this.clearSelected();
                }

                const searchTermLowerCase = term.toLocaleLowerCase();
                return searchTermLowerCase === ''
                    ? this.models
                    : this.models.filter((model) => model.name && model.name.toLocaleLowerCase().indexOf(searchTermLowerCase) > -1);
            })
        );
    };

    formatSelectedInputValue = (model: ModelsListItemSimple) => `${model.name || ''}`;

    clearSelected() {
        if (this.vehicle) {
            this.vehicle.setValue(undefined);
        }
    }

    onBlur() {
        if (!this.vehicle?.value || !this.vehicle?.value.name) {
            this.vehicle?.setValue(undefined);
            this.vehicle?.setErrors({ invalidModel: true });
        }
    }

    onSelectedItem(event: NgbTypeaheadSelectItemEvent) {
        if (event && event.item) {
            this.vehicle?.setValue(event.item);
        } else {
            this.vehicle?.setValue(undefined);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    errors = (controlName: string) => FormService.errors(controlName, this.parentForm);
}
