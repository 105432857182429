import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { INewsletterDto, NewsletterFormSpot } from '@ncg/data';
import { firstValueFrom } from 'rxjs';

import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { TrackingService } from '../core/tracking.service';
import { SpotsConfig } from '../spots/spots-config';
import { DialogService } from '../utils/dialog/dialog.service';
import { FormService } from './form.service';

@Component({
    selector: 'ncg-newsletter',
    template: `
        <div class="newsletter" *ngIf="form">
            <form [formGroup]="form" (submit)="submit()" *ngIf="!states.submitted; else success">
                <div
                    class="newsletter__fieldset"
                    [ngClass]="{
                        'columns is-multiline field': !isSidePanel
                    }"
                >
                    <div [ngClass]="{ 'column': !isSidePanel, 'field': isSidePanel, 'is-6 pb-5': this.hasLocations }">
                        <label *ngIf="isSidePanel" for="email" class="label">{{ 'forms.email' | translate }}*</label>
                        <input
                            formControlName="email"
                            [class.is-danger]="errors('email')"
                            type="email"
                            name="email"
                            class="input"
                            [attr.placeholder]="'forms.email' | translate"
                            required
                            [attr.aria-invalid]="errors('email')"
                            [attr.aria-errormessage]="errors('email') ? 'newsletter-email_error' : null"
                        />
                        <p id="newsletter-email_error" class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                    <div *ngIf="hasLocations" [ngClass]="{ 'column': !isSidePanel, 'field': isSidePanel, 'is-6 pb-5': this.hasLocations }">
                        <ncg-location-select
                            [parentForm]="form"
                            [category]="'retail'"
                            [showLabel]="isSidePanel"
                            [isDepartmentsAllowed]="false"
                            [locationWhitelist]="data.locationWhitelist || []"
                            [locationBlacklist]="data.locationBlacklist || []"
                            [isTouched]="form?.get('location')?.touched"
                            (isLocationsFound)="onLocationState($event)"
                        ></ncg-location-select>
                    </div>
                    <div [ngClass]="{ 'column': !isSidePanel, 'field': isSidePanel, 'is-6 pb-5': this.hasLocations }">
                        <label *ngIf="isSidePanel" for="firstname" class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            formControlName="firstname"
                            [class.is-danger]="errors('firstname')"
                            type="text"
                            name="firstname"
                            class="input"
                            [attr.placeholder]="'forms.firstname' | translate"
                            required
                            [attr.aria-invalid]="errors('firstname')"
                            [attr.aria-errormessage]="errors('firstname') ? 'newsletter-firstname_error' : null"
                        />
                        <p id="newsletter-firstname_error" class="help is-danger" *ngIf="errors('firstname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                    <div [ngClass]="{ 'column': !isSidePanel, 'field': isSidePanel, 'is-6 pb-5': this.hasLocations }">
                        <label *ngIf="isSidePanel" for="lastname" class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input
                            formControlName="lastname"
                            [class.is-danger]="errors('lastname')"
                            type="text"
                            name="lastname"
                            class="input"
                            [attr.placeholder]="'forms.lastname' | translate"
                            required
                            [attr.aria-invalid]="errors('lastname')"
                            [attr.aria-errormessage]="errors('lastname') ? 'newsletter-lastname_error' : null"
                        />
                        <p id="newsletter-lastname_error" class="help is-danger" *ngIf="errors('lastname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                    <div
                        class="newsletter__submit"
                        [ngClass]="{
                            'is-12 has-text-centered is-order-2': hasLocations,
                            'column has-text-centered-mobile': !isSidePanel,
                            'field': isSidePanel
                        }"
                    >
                        <button
                            data-testid="submit"
                            class="button is-primary"
                            [class.is-loading]="states.processing"
                            [class.is-full]="isSidePanel"
                            [disabled]="states.processing || form?.disabled"
                        >
                            {{ 'forms.submit_newsletter' | translate }}
                        </button>
                    </div>
                    <div
                        class="newsletter__permission"
                        [ngClass]="{
                            'is-order-1': hasLocations,
                            'column is-12': !isSidePanel,
                            'field': isSidePanel
                        }"
                    >
                        <ncg-consent [parentForm]="form"></ncg-consent>
                        <p class="help is-danger" *ngIf="errors('consent')">{{ 'forms.error_required_field' | translate }}</p>
                    </div>
                </div>
            </form>

            <ng-template #success> <ncg-rich-text [html]="data.successMessage"></ncg-rich-text> </ng-template>
        </div>
    `,
})
export class NewsletterComponent implements OnInit {
    @Input() data: NewsletterFormSpot;
    @Input() isSidePanel: boolean;

    form: UntypedFormGroup;

    states = {
        submitted: false,
        processing: false,
    };
    hasLocations: boolean;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly trackingService: TrackingService,
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig
    ) {}

    ngOnInit() {
        const { queryParams } = this.router.parseUrl(this.router.url);
        const { email = '', firstname = '', lastname = '' } = queryParams;

        this.form = this.fb.group(
            {
                email: this.fb.control(email, [Validators.required, Validators.email, FormService.emailValidator()]),
                firstname: this.fb.control(firstname, [Validators.required]),
                lastname: this.fb.control(lastname, [Validators.required]),
                consent: this.fb.control('', [Validators.required]),
            },
            { updateOn: 'change' }
        );

        this.checkForLocations(this.data.hasLocations);
    }

    checkForLocations(hasLocations: boolean) {
        this.hasLocations = hasLocations;
        if (this.hasLocations) {
            this.form.addControl('location', this.fb.control('', [Validators.required]));
        } else {
            this.form.removeControl('location');
        }
        this.cd.markForCheck();
    }

    submit(): void {
        if (!this.form) {
            return;
        }

        if (this.form.invalid || this.form.disabled) {
            FormService.markControlsAsTouched(this.form);
            return;
        }

        this.states.processing = true;
        this.cd.markForCheck();

        const value = this.form.value;

        const model: INewsletterDto = {
            email: value.email.toLowerCase(),
            firstname: value.firstname,
            lastname: value.lastname,
            consent: value.consent,
            meta: {
                campaign: {
                    utm: this.trackingService.getUtmValue(),
                    title: this.data.campaign,
                },
            },
        };

        if (this.hasLocations && value.location) {
            model.local_dealer = value.location.store_code;
        }

        if (this.data.interestGroups) {
            model.interestGroups = this.data.interestGroups;
        }

        firstValueFrom(this.formService.submitNewsletter(model))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;
                this.dialogService.showMessage('forms.error_field', 'forms.dialog_validation_errors');

                if (err.validationErrors.length && this.form) {
                    FormService.markValidationErrors(err.validationErrors, this.form);
                }

                this.cd.markForCheck();
            });
    }

    onLocationState(hasLocation: boolean) {
        this.checkForLocations(hasLocation);
    }

    private onSuccess() {
        const url = this.data.successRedirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);
}
