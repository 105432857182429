import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { IImageTextItem, ImageTextPickerSpot } from '@ncg/data';
import SwiperCore, { type SwiperOptions, Keyboard, Navigation, Pagination, A11y } from 'swiper';

import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';
import { randomId } from '../../core/helpers';

SwiperCore.use([Pagination, Navigation, Keyboard, A11y]);

@Component({
    selector: 'ncg-image-text-picker-spot',
    template: `
        <ng-container *ngIf="data.enableSlide ? data.amountVisibleSlides : items.length as count">
            <div class="image-text-picker-spot image-text-picker-spot--with-slides" *ngIf="data.enableSlide && items.length > 1">
                <div class="section container">
                    <div class="is-relative">
                        <swiper class="image-text-picker-spot-swiper" [config]="swiperConfig">
                            <ng-template swiperSlide *ngFor="let item of items; let index = index">
                                <div class="image-text-picker-spot--multi__item">
                                    <ncg-image-text-item
                                        [headingLevel]="headingLevel"
                                        [count]="count"
                                        [item]="item"
                                        [showMedia]="showItemMedia"
                                        [hasText]="true"
                                        [slidesPerView]="data.amountVisibleSlides"
                                        [invert]="data.invert"
                                        [alignCenter]="data.alignCenter"
                                    ></ncg-image-text-item>
                                </div>
                            </ng-template>
                        </swiper>
                        <div
                            class="image-text-picker-spot-swiper__arrows"
                            [ngClass]="{
                                'image-text-picker-spot-swiper__arrows--center': hasImage,
                                'image-text-picker-spot-swiper__arrows--bottom': !hasImage,
                                'image-text-picker-spot-swiper__arrows--single-slide': data.amountVisibleSlides === 1,
                                'is-hidden-tablet': items.length <= data.amountVisibleSlides
                            }"
                            [style.--slides-per-view]="data.amountVisibleSlides"
                        >
                            <div [class]="'swiper-button-prev is-small picker-' + this.pickerId + ' image-text-picker-spot-swiper__prev'"></div>
                            <div [class]="'swiper-button-next is-small picker-' + this.pickerId + ' image-text-picker-spot-swiper__next'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="!data.enableSlide"
                class="image-text-picker-spot"
                [ngClass]="{
                    'image-text-picker-spot--single': items.length === 1,
                    'image-text-picker-spot--multi': items.length > 1
                }"
            >
                <div *ngIf="items.length > 1; else single" class="columns">
                    <div *ngFor="let item of items; let index = index" class="column" ncgLoadIn>
                        <div
                            class="image-text-picker-spot--multi__item"
                            [ngStyle]="{
                                'text-align': data.alignCenter ? 'center' : ''
                            }"
                        >
                            <ncg-image-text-item
                                [headingLevel]="headingLevel"
                                [count]="count"
                                [item]="item"
                                [showMedia]="showItemMedia"
                                [hasText]="true"
                            ></ncg-image-text-item>
                        </div>
                    </div>
                </div>
                <ng-template #single>
                    <div
                        class="columns"
                        [ngStyle]="{
                            'flex-direction': data.invert ? 'row-reverse' : ''
                        }"
                    >
                        <div
                            class="column is-half-desktop image-text-picker-spot--single__image"
                            ncgLoadIn
                            [ngStyle]="{ 'align-self': singleImagePosition }"
                        >
                            <ncg-image-text-item
                                [headingLevel]="headingLevel"
                                [count]="count"
                                [item]="items[0]"
                                [showMedia]="true"
                            ></ncg-image-text-item>
                        </div>
                        <div
                            class="column is-half-desktop image-text-picker-spot--single__content"
                            ncgLoadIn
                            [ngStyle]="{
                                'text-align': data.alignCenter ? 'center' : '',
                                'justify-content': data.alignCenter ? 'center' : '',
                                'align-self': singleContentPosition
                            }"
                        >
                            <ncg-image-text-item
                                [headingLevel]="headingLevel"
                                [count]="count"
                                [item]="items[0]"
                                [hasText]="true"
                            ></ncg-image-text-item>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTextPickerSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'imagetextpicker';
    @Input() data: ImageTextPickerSpot;
    items: IImageTextItem[] = [];
    singleImagePosition: string;
    singleContentPosition: string;
    showItemMedia = true;
    hasImage = true;
    headingLevel: '2' | '3';
    swiperConfig: SwiperOptions;
    public pickerId: string;

    constructor(
        @Inject(SpotsConfig)
        public spotsConfig: SpotsConfig
    ) {
        super();
    }

    ngOnInit() {
        this.pickerId = randomId('image-text-picker');

        if (this.data) {
            this.items = this.data.columns;
            // only position arrows center if all slides has images
            this.hasImage = !this.data.columns.some((item) => !item.hasOwnProperty('image'));

            const imagePosition = this.items[0].imagePosition ?? 'top';
            const contentPosition = this.items[0].contentPosition ?? 'center';

            this.singleImagePosition = this._setPosition(imagePosition);
            this.singleContentPosition = this._setPosition(contentPosition);
            this.headingLevel = this.data.isHigherHeadingLevel ? '2' : '3';
        }

        this.swiperConfig = {
            slidesPerView: 1.1,
            spaceBetween: this.spotsConfig.imageTextPickerItemSpaceBetween,
            grabCursor: true,
            threshold: 10,
            navigation: {
                prevEl: `.swiper-button-prev.picker-${this.pickerId}.image-text-picker-spot-swiper__prev`,
                nextEl: `.swiper-button-next.picker-${this.pickerId}.image-text-picker-spot-swiper__next`,
            },
            keyboard: {
                enabled: true,
            },
            breakpoints: {
                '1024': { slidesPerView: this.data.amountVisibleSlides + 0.1 },
                '1600': { slidesPerView: this.data.amountVisibleSlides },
            },
            simulateTouch: this.items.length > this.data.amountVisibleSlides,
        };
    }

    private _setPosition(key: string): string {
        switch (key) {
            case 'center':
                return 'center';
            case 'bottom':
                return 'flex-end';
            default:
                return 'flex-start';
        }
    }
}
