import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { navigateTab } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { FooterMinimalComponent } from '../../../footer-minimal/footer-minimal.component';
import { DialogComponentModule } from '../../../utils/dialog/dialog.component';
import { DialogService } from '../../../utils/dialog/dialog.service';
import { ConfiguratorFooterSummaryComponent } from './configurator-footer-summary/configurator-footer-summary.component';

@Component({
    selector: 'ncg-configurator-footer',
    template: `
        <ncg-footer-minimal>
            <div class="content">
                <span *ngIf="configuratorFacade.showPrices$ | async">
                    {{ 'configurator.price_total' | translate }}
                    <span class="configurator__text--bold">{{ (configuratorFacade.price$ | async)?.total?.priceFormatted }}</span></span
                >
                <button class="button is-primary flex-right" (click)="nextTab()">{{ 'configurator.tab_next' | translate }}</button>
                <button class="toggle-overview" (click)="toggleOverview()" [attr.aria-label]="'configurator.summary_heading' | translate">
                    <svg-icon-sprite
                        [width]="'24px'"
                        [height]="'24px'"
                        [src]="'chevron-up'"
                        [viewBox]="'0 0 30 30'"
                        aria-hidden="true"
                        class="icon"
                    ></svg-icon-sprite>
                </button>
            </div>
        </ncg-footer-minimal>
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./configurator-footer.component.scss'],
    imports: [CommonModule, FooterMinimalComponent, IconSpriteModule, DialogComponentModule, ConfiguratorFooterSummaryComponent, TranslateModule],
})
export class ConfiguratorFooterComponent {
    constructor(
        private readonly dialogService: DialogService,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    nextTab(): void {
        this.configuratorFacade.dispatch(navigateTab({ direction: 'right' }));
    }

    toggleOverview(): void {
        this.dialogService.openDialogWithComponent(ConfiguratorFooterSummaryComponent, {
            fullHeight: true,
            fullWidth: true,
            blockScroll: true,
            hasBackdrop: true,
        });
    }
}
