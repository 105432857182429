import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IImage, INavigationResponse } from '@ncg/data';

import { HdAspect } from '../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../utils/helpers/image-helper';

@Component({
    selector: 'ncg-campaigns-spot-item',
    template: `
        <article class="campaign-spot-item" *ngIf="campaign">
            <a [routerLink]="campaign.url">
                <picture class="animate-mask is-x-small-mask is-ratio-hd" *ngIf="pictureSrc">
                    <source media="(min-width: 768px)" [srcset]="imageSrcSet" />
                    <source media="(max-width: 767px)" [srcset]="mobileImageSrcSet" />
                    <img [src]="pictureSrc" [srcset]="imageSrcSet" [alt]="pictureAlt" loading="lazy" ncgImageLoad />
                </picture>
            </a>

            <div class="campaign-spot-item__content">
                <div class="rte-content">
                    <div *ngIf="campaign.name">
                        <h3 class="campaign-spot-item__title">{{ campaign.name }}</h3>
                    </div>
                    <ncg-rich-text [html]="campaign.description"></ncg-rich-text>
                </div>

                <div class="campaign-spot-item__prices">
                    <div class="columns is-mobile is-multiline">
                        <div class="campaign-spot-item__price column is-narrow" *ngIf="campaign.salesPrice">
                            <div class="campaign-spot-item__price--label">{{ 'campaigns.sales_price' | translate }}</div>
                            <div class="campaign-spot-item__price--value">{{ campaign.salesPrice }}</div>
                        </div>
                        <div class="campaign-spot-item__price column" *ngIf="campaign.leasingPrice">
                            <div class="campaign-spot-item__price--label">{{ 'campaigns.leasing_price' | translate }}</div>
                            <div class="campaign-spot-item__price--value">{{ campaign.leasingPrice }}</div>
                        </div>
                    </div>
                </div>

                <div class="campaign-spot-item__actions">
                    <a class="button" [ngClass]="isCampaignType ? 'is-primary' : 'is-secondary'" [routerLink]="campaign.url">
                        <ng-container *ngIf="isCampaignType; else defaultLink">{{ 'campaigns.campaign_link' | translate }}</ng-container>
                        <ng-template #defaultLink>{{ 'campaigns.regular_link' | translate }}</ng-template>
                    </a>
                </div>
            </div>
        </article>
    `,
})
export class CampaignsSpotItemComponent implements OnInit {
    @Input() campaign: INavigationResponse;
    pictureSrc = '';
    mobileImageSrcSet: string = '';
    imageSrcSet = '';
    pictureAlt = '';
    isCampaignType: boolean = false;

    constructor(private readonly cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.isCampaignType = ['campaignSubpage', 'standardCampaignPage'].includes(this.campaign.template);
        if (this.campaign.image) {
            this.pictureAlt = this.campaign.image.altText || this.campaign.name;
            this.generateSrcSet(this.campaign.image);
        }
        this.cd.markForCheck();
    }

    private generateSrcSet(image: IImage) {
        const aspectRatio = HdAspect;
        const width = 578;
        const mobileWidth = 330;

        this.mobileImageSrcSet = `
            ${ImageUrl(image, { width: mobileWidth, heightratio: aspectRatio, mode: 'crop' })} 1x,
            ${ImageUrl(image, { width: mobileWidth * 2, heightratio: aspectRatio, mode: 'crop' })} 2x
            `;
        this.imageSrcSet = `
            ${ImageUrl(image, { width, heightratio: aspectRatio, mode: 'crop' })} 1x,
            ${ImageUrl(image, { width: width * 2, heightratio: aspectRatio, mode: 'crop' })} 2x
            `;

        this.pictureSrc = ImageUrl(image, { width, heightratio: aspectRatio, mode: 'crop' });
    }
}
