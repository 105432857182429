import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SpotBaseDirective } from '../spot-base.class';
import { SpotsConfig } from '../spots-config';
import { getBuildIdQuery, HeroSliderSpot } from '@ncg/data';
import SwiperCore, { type SwiperOptions, Navigation, Pagination, Autoplay, Keyboard, A11y } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

SwiperCore.use([Navigation, Pagination, Autoplay, Keyboard, A11y]);
@Component({
    selector: 'ncg-hero-slider-spot',
    template: `
        <div class="hero-slider-spot">
            <div *ngIf="data?.extendedHeroSpots?.length">
                <div>
                    <swiper #swiperRef class="image-text-picker-spot-swiper" [config]="swiperConfig">
                        <ng-template swiperSlide *ngFor="let item of data.extendedHeroSpots; let i = index" inert>
                            <ncg-extended-hero-spot [data]="item"></ncg-extended-hero-spot>
                        </ng-template>
                    </swiper>
                    <div class="hero-slider-spot__carousel-nav-container">
                        <div
                            class="hero-slider-spot__carousel-nav"
                            [ngClass]="{
                                'legal-text-moved': anySlideHasLegalTextPosition,
                                'legal-text-moved-desktop': anyDesktopSlideHasLegalTextPosition
                            }"
                        >
                            <div class="swiper-button-prev hero-slider-spot-button"></div>
                            <div class="hero-slider-spot__pagination"></div>
                            <div class="swiper-button-next hero-slider-spot-button"></div>
                            <button class="hero-slider-spot__pause-button" (click)="toggleAutoplay()">
                                <ng-container *ngIf="playingState === 'playing'; else paused">
                                    <svg-icon-sprite
                                        [src]="'assets/images/sprite.svg' + buildIdQuery + '#pause'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                    ></svg-icon-sprite>
                                </ng-container>

                                <ng-template #paused>
                                    <svg-icon-sprite
                                        [src]="'assets/images/sprite.svg' + buildIdQuery + '#play-outline'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                    ></svg-icon-sprite>
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class HeroSliderSpotComponent extends SpotBaseDirective implements OnInit, AfterViewInit {
    static ref = 'heroslider';
    @Input() data: HeroSliderSpot;
    @Input() spotsConfig: SpotsConfig;
    @Input() legalText: boolean = false;
    @ViewChild('swiperRef', { static: false }) swiper: SwiperComponent;
    buildIdQuery = getBuildIdQuery();
    anySlideHasLegalTextPosition: boolean = false;
    anyDesktopSlideHasLegalTextPosition: boolean = false;
    playingState: 'playing' | 'paused' = 'playing';

    swiperConfig: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 0,
        grabCursor: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: true,
        },
        pagination: {
            el: '.hero-slider-spot__pagination',
            clickable: true,
        },
        navigation: {
            prevEl: '.swiper-button-prev.hero-slider-spot-button',
            nextEl: '.swiper-button-next.hero-slider-spot-button',
        },
        keyboard: {
            enabled: true,
        },
        on: {
            autoplayStop: () => {
                // reset to paused when disableOnInteraction is true
                this.playingState = 'paused';
            },
        },
    };

    constructor(readonly cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.anySlideHasLegalTextPosition = this.data?.extendedHeroSpots?.some((spot) => !!spot.showLegalTextUnderMobile) || false;
        this.anyDesktopSlideHasLegalTextPosition = this.data?.extendedHeroSpots?.some((spot) => !!spot.showLegalTextUnderDesktop) || false;
    }

    ngAfterViewInit() {
        if (this.swiper && this.swiper.swiperRef) {
            this.swiper.swiperRef.on('slideChange', ({ activeIndex, slides }) => {
                // update inert state on all slides
                slides.forEach((slide) => ((slide as HTMLElement).inert = true));
                (slides[activeIndex] as HTMLElement).inert = false;

                this.cdr.markForCheck();
            });
        }
    }

    toggleAutoplay() {
        if (this.swiper.swiperRef?.autoplay.running) {
            this.swiper.swiperRef?.autoplay.stop();
            this.playingState = 'paused';
        } else {
            this.swiper.swiperRef?.autoplay.start();
            this.playingState = 'playing';
        }
    }
}
