import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ITimelineItem } from '@ncg/data';
import { expansionAnimation } from '../../expansion-panel/expansion.animation';

@Component({
    selector: 'ncg-timeline-item',
    template: `
        <div class="timeline-item">
            <div class="timeline-item__image">
                <picture *ngIf="item.image as image" class="is-ratio-4-3">
                    <source media="(min-width: 1024px)" [srcset]="image | imageUrl: { width: 490, mode: 'crop', heightratio: 0.75 } : 2" />
                    <source media="(min-width: 768px)" [srcset]="image | imageUrl: { width: 994, mode: 'crop', heightratio: 0.75 } : 2" />
                    <source media="(min-width: 415px)" [srcset]="image | imageUrl: { width: 385, mode: 'crop', heightratio: 0.75 } : 2" />
                    <source media="(max-width: 414px)" [srcset]="image | imageUrl: { width: 345, mode: 'crop', heightratio: 0.75 } : 2" />
                    <img
                        [src]="image | imageUrl: { width: 490, mode: 'crop', heightratio: 0.75 }"
                        [alt]="image.altText || item.title || ''"
                        loading="lazy"
                        ncgImageLoad
                    />
                </picture>
            </div>
            <div class="timeline-item__content">
                <div
                    class="timeline-item__inner"
                    [@expandAnimation]="{
                        value: expandedState,
                        params: {
                            closedHeight: 'var(--timeline-item-height)'
                        }
                    }"
                >
                    <p class="timeline-item__label" *ngIf="item.label">{{ item.label }}</p>
                    <h2 class="timeline-item__heading" *ngIf="item.title">{{ item.title }}</h2>
                    <ncg-rich-text *ngIf="item.description" [html]="item.description"></ncg-rich-text>
                </div>
                <button class="button is-link" (click)="toggleText()" *ngIf="isTextToggleVisible">
                    <ng-container *ngIf="!isDescriptionExpanded">{{ 'product_details.read_more_button' | translate }}</ng-container>
                    <ng-container *ngIf="isDescriptionExpanded">{{ 'product_details.read_less_button' | translate }}</ng-container>
                </button>
            </div>
        </div>
    `,
    animations: [expansionAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineItemComponent implements OnInit {
    @Input() item: ITimelineItem;
    isDescriptionExpanded = false;
    isTextToggleVisible = false;
    expandedState?: 'expanded' | 'closed' = 'closed';
    maxContentLength = 500;
    @ViewChild('itemContent') itemContent: ElementRef<HTMLDivElement>;

    ngOnInit(): void {
        if (this.item.description) {
            const text = this.item.description;

            this.isTextToggleVisible = text.length > this.maxContentLength;
            if (!this.isTextToggleVisible) {
                this.isDescriptionExpanded = true;
            }

            this.expandedState = this.isDescriptionExpanded ? 'expanded' : 'closed';
        }
    }

    toggleText() {
        this.isDescriptionExpanded = !this.isDescriptionExpanded;
        this.expandedState = this.isDescriptionExpanded ? 'expanded' : 'closed';
    }
}
