import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    QueryList,
    ViewChild,
} from '@angular/core';
import { ScrollService } from '../core/scroll.service';

import { TabComponent } from './tab.component';

@Component({
    selector: 'ncg-tab-group',
    template: `
        <div class="tab-group">
            <div class="tab-group__tabs" role="tablist" #tabsWrapper>
                <button
                    class="tab-group__tabs--item {{ 'tab-id-' + i }}"
                    role="tab"
                    *ngFor="let label of labels; let i = index"
                    [id]="'tab-' + ids[i]"
                    [class.is-active]="i === activeTab"
                    [attr.aria-controls]="'panel-' + ids[i]"
                    [attr.aria-selected]="activeTab === i"
                    (click)="setActive(i)"
                >
                    {{ label }}
                </button>
            </div>

            <div class="tab-group__content-wrapper"><ng-content></ng-content></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent implements AfterContentInit, AfterContentChecked {
    @ContentChildren(TabComponent) _tabs: QueryList<TabComponent>;

    @ViewChild('tabsWrapper') _navElement: ElementRef<HTMLDivElement>;

    ids: string[] = [];
    labels?: string[];
    activeTab = 0;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService
    ) {}

    ngAfterContentInit() {
        if (this._tabs?.length) {
            // fill ids array with random ids
            this.ids = this._tabs.map(() => Math.random().toString(36).substring(2));

            // forward id to tab
            this._tabs.forEach((tabContent, i) => {
                const id = this.ids[i];
                tabContent.setId(id);
            });
        }
    }

    ngAfterContentChecked() {
        if (this._tabs && this._tabs.length) {
            this.labels = this._tabs.map((x) => x.label || '');
            this.cd.markForCheck();
        }
    }

    setActive(index: number): void {
        this.activeTab = index;
        this._tabs.forEach((x) => x.setActive(false));

        const activeTab = this._tabs.toArray()[this.activeTab];
        if (activeTab) {
            activeTab.setActive(true);
        }

        if (this._navElement) {
            const navElementWidth = this._navElement.nativeElement.offsetWidth;
            const activeLink = this._navElement.nativeElement.querySelector<HTMLDivElement>(`.tab-id-${this.activeTab}`);

            if (activeLink) {
                const activeLinkLeft = activeLink.offsetLeft;
                const activeLinkWidth = activeLink.offsetWidth;

                let left = 0;
                if (this.activeTab !== 0) {
                    left = activeLinkLeft - navElementWidth / 2 + activeLinkWidth / 2;
                }
                this.scrollService.scrollToPosition({ left }, this._navElement.nativeElement);
            }
        }
    }
}
