import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { ListItemDirective } from '../../../list-item/list-item.directive';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { DialogService } from '../../../utils/dialog/dialog.service';
import { ConfiguratorImageComponent } from '../../components';
import { ConfiguratorTabStates } from '../../configurator';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { ExteriorColorByVariantPipe } from '../../utils/exterior-color-by-variant.pipe';
import { GetPriceDifferencePipe } from '../../utils/get-price-difference.pipe';
import { KeyByIdPipe } from '../../utils/key-by-id.pipe';

@Component({
    selector: 'ncg-configurator-body-style',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <div class="container" *ngIf="configuratorFacade.model$ | async as model" [@listAnimation]="model.bodyStyles?.length">
                <div class="configurator__cards">
                    <ng-container *ngFor="let bodyStyle of model.bodyStyles">
                        <ncg-card class="configurator__card" contentClass="configurator__card-content" [selected]="bodyStyle.id === params.bodystyle">
                            <ncg-configurator-image
                                [fields]="bodyStyle?.resources?.[0]?.fields"
                                sizes="300px"
                                type="car"
                                card-image
                            ></ncg-configurator-image>
                            <h2 class="title configurator__headline" *ngIf="configuratorFacade.tabStates$ | async as tabStates">
                                <button
                                    type="button"
                                    class="configurator__font-weight--emphasis body-style-button configurator__link-cover"
                                    (click)="handleBodyStyleChange(tabStates, bodyStyle.id)"
                                >
                                    {{ model.title }} <br />
                                    <span class="body-style-name">{{ bodyStyle.fields | dataById: 'BodyStyleMarketingTitle' }}</span>
                                </button>
                            </h2>
                        </ncg-card>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .body-style-button {
                font-size: inherit;
                font-family: inherit;
                background: transparent;
                cursor: pointer;
                text-align: left;
            }
            .body-style-name {
                display: inline-block;
                margin-top: 0.3rem;
                font-size: var(--font-size-base);
                color: var(--configurator-accent-color);
                font-family: var(--font-family-base);
            }
        `,
    ],
    imports: [
        CommonModule,
        CardComponent,
        RouterModule,
        TranslateModule,
        DataByIdPipe,
        CurrencyFormatterPipe,
        AddCurrencyPipe,
        GetPriceDifferencePipe,
        ListItemDirective,
        KeyByIdPipe,
        ConfiguratorImageComponent,
        ExteriorColorByVariantPipe,
    ],
})
export class ConfiguratorBodyStyleComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly router: Router,
        private readonly dialogService: DialogService,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public handleBodyStyleChange(tabStates: ConfiguratorTabStates, id?: string | null): void {
        const doBodyStyleChange = () => {
            this.router.navigate([], { queryParams: { bodystyle: id }, queryParamsHandling: 'merge', replaceUrl: true });
        };

        const isStateDirty = Object.entries(tabStates)
            .filter(([tab]) => tab === 'bodystyle')
            .some(([, state]) => state !== 'pristine');

        if (!isStateDirty) {
            doBodyStyleChange();
            return;
        }

        this.dialogService
            .openDialog({
                data: {
                    header: 'configurator.tab_state_dialog_heading',
                    text: 'configurator.tab_state_dialog_body',
                    okBtn: 'configurator.tab_state_dialog_ok_button',
                    closeBtn: 'configurator.tab_state_dialog_close_button',
                },
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return;
                }
                dialogRef
                    .afterClose()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            doBodyStyleChange();
                        }
                    });
            });
    }
}
