import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ILink } from '@ncg/data';

@Component({
    selector: 'ncg-link',
    template: `
        <a
            [attr.rel]="link.isExternal ? 'noopener noreferrer' : null"
            [href]="url"
            [attr.title]="title"
            [attr.target]="link.target ? link.target : null"
            [attr.tabindex]="tabindex"
            [ngClass]="[cover ? 'link-cover' : '', styleClass]"
            draggable="false"
            ncgInterceptLinks
            [stopPropagation]="stopPropagation"
        >
            <span #content [class]="styleClass | linkContentClass: '-wrapper'">
                <ng-content></ng-content>
            </span>

            {{ content.hasChildNodes() ? null : link.name }}
        </a>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnInit {
    public url: string;

    @Input() public link: ILink;
    @Input() public title?: string;
    @Input() public styleClass: string = '';
    @Input() public cover: boolean = false;
    @Input() public queryParams?: Params | null;
    @Input() public stopPropagation: boolean = false;
    @Input() public tabindex: number;

    constructor(private readonly router: Router) {}

    ngOnInit(): void {
        if (this.queryParams && !this.link.isExternal) {
            const fullUrl = this.router.createUrlTree([this.link.url], { queryParams: this.queryParams });
            this.url = fullUrl.toString();
        } else {
            this.url = this.link.url;
        }
    }
}
