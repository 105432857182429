import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

let dialogElementUid = 0;

export class ImpactOverlayRef {
    readonly id: string = `impact-dialog-${dialogElementUid++}`;

    componentInstance: any;

    private _beforeClose$: Subject<any> = new Subject<any>();
    private _afterClose$: Subject<any> = new Subject<any>();

    constructor(private readonly overlayRef: OverlayRef) {}

    cdkRef: OverlayRef = this.overlayRef;

    close(dialogResult?: any): void {
        // move focus back by removing inert from page
        document.querySelector('ncg-root')?.removeAttribute('inert');

        if (this.componentInstance.animationStateChanged) {
            /**
             * Detaching the overlay
             */
            this.componentInstance.animationStateChanged
                .pipe(
                    filter((event: AnimationEvent) => event.phaseName === 'start'),
                    take(1)
                )
                .subscribe(() => {
                    this._beforeClose$.next(dialogResult);
                    this._beforeClose$.complete();
                    this.overlayRef.detachBackdrop();
                });

            /**
             * Disposing the overlay and cleaning up
             */
            this.componentInstance.animationStateChanged
                .pipe(
                    filter((event: AnimationEvent) => event.phaseName === 'done' && event.toState === 'leave'),
                    take(1)
                )
                .subscribe(() => {
                    this.overlayRef.dispose();
                    this._afterClose$.next(dialogResult);
                    this._afterClose$.complete();

                    // Make sure to also clear the reference to the
                    // component instance to avoid memory leaks
                    this.componentInstance = null;
                });

            this.componentInstance.startExitAnimation();
        } else {
            this._beforeClose$.next(dialogResult);
            this._beforeClose$.complete();

            this.overlayRef.dispose();

            this._afterClose$.next(dialogResult);
            this._afterClose$.complete();
        }
    }

    beforeClose(): Observable<any> {
        return this._beforeClose$.asObservable();
    }

    afterClose(): Observable<any> {
        return this._afterClose$.asObservable();
    }
}
