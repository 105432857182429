import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ILink, ITestDriveDto, NcgLocationFormCategory, NgcApiLocation, OpeningHours, TestDriveFormSpot } from '@ncg/data';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AppHttpErrorResponse } from '../core/app-http-error.response';
import { leftFillNum, randomId } from '../core/helpers';
import { MetaService } from '../core/meta.service';
import { ScrollService } from '../core/scroll.service';
import { SettingsService } from '../core/settings.service';
import { TrackingService } from '../core/tracking.service';
import { SidePanelService } from '../side-panel/side-panel.service';
import { I18n } from '../utils/helpers/datepicker-i18n';
import { FormService } from './form.service';

export interface ITestDriveUsedModel {
    id: string;
    make: string;
    model: string;
    location?: NgcApiLocation;
}

const WEEKDAY_NAME_MAP = ['unknown', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

@Component({
    selector: 'ncg-test-drive-form',
    template: `
        <div #mainElement class="test-drive-form" *ngIf="form">
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="!states.submitted; else success">
                <ncg-models-select
                    *ngIf="showModelsSelect && data && data.models"
                    [parentForm]="form"
                    [isTouched]="vehicle?.touched"
                    [models]="data.models"
                ></ncg-models-select>
                <ncg-location-select
                    *ngIf="allowSelectLocation && data"
                    [parentForm]="form"
                    [allowedCategory]="data.availableCategory"
                    [allowShowrooms]="true"
                    [allowTestdriveonly]="true"
                    [preferTestdriveonly]="true"
                    [isTouched]="location?.touched"
                    [category]="category"
                    [locationWhitelist]="data.locationWhitelist"
                    [locationBlacklist]="data.locationBlacklist"
                    (isLocationsFound)="onLocationState($event)"
                ></ncg-location-select>
                <div class="field" (click)="onClick('date')">
                    <label [for]="dateId" class="label">{{ 'forms.datepicker_label' | translate }}*</label>
                    <div class="control has-icons-right" [ngClass]="{ 'is-custom-disabled': location?.invalid }">
                        <input
                            [id]="dateId"
                            [attr.tabindex]="location?.invalid ? -1 : null"
                            class="input"
                            autocomplete="off"
                            placeholder="dd-mm-yyyy"
                            name="dp"
                            formControlName="datetime"
                            [class.is-danger]="(datePicker?.dirty && datePicker?.errors) || showDatePickerError"
                            [attr.aria-invalid]="(datePicker?.dirty && datePicker?.errors) || showDatePickerError"
                            [attr.aria-errormessage]="(datePicker?.dirty && datePicker?.errors) || showDatePickerError ? dateId + '_error' : null"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            ngbDatepicker
                            [autoClose]="true"
                            [markDisabled]="disabledDays"
                            #dp="ngbDatepicker"
                            (click)="dp.open()"
                            (focus)="!dontOpenDatepicker && dp.open(); dontOpenDatepicker = dp.isOpen()"
                            ncgDatepickerI18n
                        />
                        <span class="icon date-icon is-right" [ngClass]="{ 'is-clickable': location?.valid }" (click)="dp.toggle()">
                            <svg-icon-sprite
                                [src]="'calendar'"
                                [viewBox]="'0 0 30 30'"
                                [width]="'30px'"
                                [height]="'30px'"
                                aria-hidden="true"
                                classes=""
                            ></svg-icon-sprite>
                        </span>
                        <p [id]="dateId + '_error'" class="help is-danger" *ngIf="errors('datetime') || showDatePickerError">
                            {{ 'forms.datepicker_invalid_date' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field" (click)="onClick('time')">
                    <label [for]="timeId" class="label">{{ 'forms.test_drive_time' | translate }}*</label>
                    <div class="control">
                        <div class="select is-full" [class.is-danger]="(time?.touched && time?.errors) || showTimeError">
                            <select
                                [id]="timeId"
                                name="time"
                                formControlName="time"
                                required
                                [class.is-disabled]="datePicker?.invalid || showTimeError"
                                [attr.aria-invalid]="(time?.touched && time?.errors) || showTimeError"
                                [attr.aria-errormessage]="(time?.touched && time?.errors) || showTimeError ? timeId + '_error' : null"
                            >
                                <option selected="selected" value="">{{ 'forms.time_label' | translate }}</option>
                                <option *ngFor="let hour of locationHours" [value]="hour.startHour + ':' + (hour.startMinute | pad: 2 : '0' : 'end')">
                                    {{ hour.startHour }}:{{ hour.startMinute | pad: 2 : '0' : 'end' }} - {{ hour.endHour }}:{{
                                        hour.endMinute | pad: 2 : '0' : 'end'
                                    }}
                                </option>
                            </select>
                        </div>
                        <p [id]="timeId + '_error'" class="help is-danger" *ngIf="(time?.touched && time?.errors) || showTimeError">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="firstNameId" class="label">{{ 'forms.firstname' | translate }}*</label>
                        <input
                            [id]="firstNameId"
                            name="firstname"
                            formControlName="firstname"
                            class="input"
                            [class.is-danger]="errors('firstname')"
                            [attr.aria-invalid]="errors('firstname')"
                            [attr.aria-errormessage]="errors('firstname') ? firstNameId + '_error' : null"
                            type="text"
                            required
                        />
                        <p [id]="firstNameId + '_error'" class="help is-danger" *ngIf="errors('firstname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="lastNameId" class="label">{{ 'forms.lastname' | translate }}*</label>
                        <input
                            [id]="lastNameId"
                            name="lastname"
                            formControlName="lastname"
                            class="input"
                            [class.is-danger]="errors('lastname')"
                            [attr.aria-invalid]="errors('lastname')"
                            [attr.aria-errormessage]="errors('lastname') ? lastNameId + '_error' : null"
                            type="text"
                            required
                        />
                        <p [id]="lastNameId + '_error'" class="help is-danger" *ngIf="errors('lastname')">
                            {{ 'forms.error_required_field' | translate }}
                        </p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <label [for]="emailId" class="label">{{ 'forms.email' | translate }}*</label>
                        <input
                            [id]="emailId"
                            name="email"
                            formControlName="email"
                            class="input"
                            [class.is-danger]="errors('email')"
                            [attr.aria-invalid]="errors('email')"
                            [attr.aria-errormessage]="errors('email') ? emailId + '_error' : null"
                            type="email"
                            required
                        />
                        <p [id]="emailId + '_error'" class="help is-danger" *ngIf="errors('email')">{{ 'forms.error_email' | translate }}</p>
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <div class="field">
                            <label [for]="phoneId" class="label">{{ 'forms.mobile' | translate }}*</label>
                            <!-- country code input -->
                            <div class="phone">
                                <span class="phoneCode">
                                    <input
                                        [id]="countryCodeId"
                                        style="width: 100px; margin-right: 2px"
                                        type="country-code"
                                        autocomplete="tel-country-code"
                                        class="input"
                                        name="selectedCountryCode"
                                        formControlName="selectedCountryCode"
                                        [class.is-danger]="errors('selectedCountryCode')"
                                        [attr.aria-invalid]="errors('selectedCountryCode')"
                                        [attr.aria-errormessage]="errors('selectedCountryCode') ? countryCodeId + '_error' : null"
                                    />
                                </span>
                                <span class="phoneOnly">
                                    <input
                                        [id]="phoneId"
                                        name="phone"
                                        formControlName="phone_mobile"
                                        class="input"
                                        type="tel"
                                        autocomplete="tel-national"
                                        [class.is-danger]="errors('phone_mobile')"
                                        [attr.aria-invalid]="errors('phone_mobile')"
                                        [attr.aria-errormessage]="errors('phone_mobile') ? phoneId + '_error' : null"
                                    />
                                </span>
                            </div>
                        </div>
                        <p [id]="countryCodeId + '_error'" class="help is-danger" *ngIf="errors('selectedCountryCode')">
                            {{ 'forms.error_phone_country' | translate }}
                        </p>
                        <p [id]="phoneId + '_error'" class="help is-danger" *ngIf="errors('phone_mobile')">{{ 'forms.error_phone' | translate }}</p>
                    </div>
                </div>
                <div class="field" *ngIf="collectMarketingConsent">
                    <div class="control"><ncg-consent [parentForm]="form" [identifier]="usedCar?.location?.business?.identifier"></ncg-consent></div>
                </div>
                <div class="field is-grouped is-grouped-centered is-not-column">
                    <div class="control">
                        <button class="button is-primary" [class.is-loading]="states.processing" [disabled]="states.processing">
                            {{ 'forms.submit_inquiry' | translate }}
                        </button>
                    </div>
                </div>
                <ncg-legal *ngIf="privacyPolicy" [legalText]="privacyPolicy"></ncg-legal>
                <div class="notification is-primary" *ngIf="errorState">
                    <ng-container *ngIf="errorState === 'api'; else genericError"> {{ 'forms.error_field' | translate }} </ng-container>
                    <ng-template #genericError>{{ 'forms.error_submit' | translate }}</ng-template>
                </div>
            </form>
            <ng-template #success><ncg-rich-text [html]="successMessage"></ncg-rich-text></ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestDriveFormComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    private readonly blockedDates: Pick<NgbDateStruct, 'day' | 'month'>[] = [
        { day: 24, month: 12 },
        { day: 25, month: 12 },
        { day: 26, month: 12 },
        { day: 31, month: 12 },
        { day: 1, month: 1 },
    ];

    @Input() data?: TestDriveFormSpot;
    @Input() isModelPage = false;
    @Input() successMessage? = '';
    @Input() redirect?: ILink;
    @Input() usedCar?: ITestDriveUsedModel;
    @Input() testDriveStartDate?: string;
    @Input() campaign?: string;
    @Input() listId?: string;
    @Input() collectMarketingConsent = true;

    @ViewChild('mainElement') private mainElement?: ElementRef<HTMLDivElement>;
    showModelsSelect = false;
    allowSelectLocation = true;
    showDatePickerError = false;
    showTimeError = false;
    states = {
        submitted: false,
        processing: false,
    };

    errorState: '' | 'api' | 'server' = '';
    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    disabledDays: (date: NgbDate, current: { month: number } | undefined) => boolean;
    dontOpenDatepicker = false;
    isLocationsDisabled = true;
    category: NcgLocationFormCategory = 'retail';
    locationHours: {
        startHour: number;
        startMinute: number;
        endHour: number;
        endMinute: number;
    }[] = [];
    privacyPolicy?: string;
    firstNameId: string;
    lastNameId: string;
    emailId: string;
    countryCodeId: string;
    phoneId: string;
    dateId: string;
    timeId: string;
    isSidePanel: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private formService: FormService,
        private readonly cd: ChangeDetectorRef,
        private ngbCalendar: NgbCalendar,
        private readonly router: Router,
        private readonly metaService: MetaService,
        private readonly settingsService: SettingsService,
        private readonly trackingService: TrackingService,
        private readonly scrollService: ScrollService,
        private readonly sidePanelService: SidePanelService,
        private readonly _i18n: I18n // Not ideal, but to combat async issues with i18n and datePicker, we inject the service here, to make sure the correct language is set
    ) {}

    form = this.fb.group(
        {
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            email: ['', [Validators.email, Validators.required, FormService.emailValidator()]],
            phone_mobile: ['', [Validators.required, FormService.countryPhoneValidator(''), FormService.numbersOnlyValidator()]],
            selectedCountryCode: ['', [Validators.required, FormService.countryCodeValidator()]],
            consent: '',
            datetime: ['', [Validators.required]],
            time: ['', [Validators.required]],
            vehicle: ['', [Validators.required]],
            location: ['', [Validators.required]],
        },
        { updateOn: 'change' }
    );
    ngOnInit() {
        this.sidePanelService
            .isSidePanel()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isSidePanel) => {
                this.isSidePanel = isSidePanel;
            });

        this.getSettings();
        this.listenForLocation();
        this.listenForDatePicker();
        this.setDatePickerSettings();
    }

    getSettings() {
        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.privacyPolicy = settings.privacyPolicy;
                this.cd.markForCheck();

                // Create the form inside the settings subscribe block
                this.createForm();

                // Patch the form value inside the settings subscribe block
                this.form?.patchValue({
                    selectedCountryCode: FormService.convertCountryCodeToPhoneCode(settings.seoCountry),
                });
            });
    }

    createForm() {
        this.firstNameId = randomId('firstname');
        this.lastNameId = randomId('lastname');
        this.emailId = randomId('email');
        this.countryCodeId = randomId('countryCode');
        this.phoneId = randomId('phone');
        this.dateId = randomId('date');
        this.timeId = randomId('time');

        this.showModelsSelect = !this.isModelPage;

        if (this.data?.models) {
            this.showModelsSelect = this.data?.models.length > 1;
        }

        if (this.data?.models?.length === 1) {
            this.vehicle?.patchValue(this.data.models[0]);
        } else if (this.usedCar) {
            this.vehicle?.patchValue(this.usedCar.id);

            if (this.usedCar.location) {
                this.location?.patchValue(this.usedCar.location);
                this.location?.updateValueAndValidity();
                this.allowSelectLocation = false;
            }
        }

        if (this.location?.invalid) {
            this.datePicker?.disable({ onlySelf: true });
            this.time?.disable({ onlySelf: true });
        }
    }

    listenForLocation() {
        this.location?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value: NgcApiLocation) => {
            if (this.allowSelectLocation) {
                this.datePicker?.reset();
                if (value) {
                    this.datePicker?.enable({ onlySelf: true });
                    this.setDatePickerSettings();
                } else {
                    this.datePicker?.disable({ onlySelf: true });
                }

                this.cd.markForCheck();
            }
        });
    }

    listenForDatePicker() {
        this.datePicker?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((data: string) => {
            this.showDatePickerError = false;

            this.time?.reset();
            this.time?.disable({ onlySelf: true });

            if (data) {
                this.time?.enable({ onlySelf: true });
                this.setLocationHours();
            }

            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setDatePickerSettings() {
        const currentDate = new Date();
        const defaultStartDate = new Date();
        let disabledFromDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() + (this.data?.numberOfDaysDisabled || 3)));

        if (this.testDriveStartDate) {
            const startDate = new Date(this.testDriveStartDate);
            if (startDate.getTime() > currentDate.getTime()) {
                disabledFromDate = startDate;
            }
        }

        this.disabledDays = (date: NgbDate): boolean => {
            // Check if the date is in the blockedDates array.
            const isBlocked = this.blockedDates.some(({ day, month }) => month === date.month && day === date.day);
            if (isBlocked) {
                return true;
            }

            // Check location-specific opening hours for date.
            const weekday = this.ngbCalendar.getWeekday(date);
            const weekDayName = WEEKDAY_NAME_MAP[weekday];
            const location: NgcApiLocation | undefined = this.location?.value;
            const openingHours = location?.opening_hours?.find((d) => d.open_day === weekDayName);

            return !this.isOpen(openingHours);
        };

        this.minDate = {
            day: disabledFromDate.getDate(),
            month: disabledFromDate.getMonth() + 1,
            year: disabledFromDate.getFullYear(),
        };

        this.maxDate = {
            day: currentDate.getDate(),
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear() + 2,
        };
    }

    onLocationState(event: any) {
        this.isLocationsDisabled = event;

        if (!this.isLocationsDisabled && this.form) {
            this.form.disable();
            this.errorState = 'server';
        }
    }

    onClick(input: 'date' | 'time') {
        if (this.location?.invalid) {
            this.location?.markAsTouched();
            this.location?.setErrors({ invalidLocation: true });
        }

        if (input === 'time') {
            this.showDatePickerError = Boolean(this.datePicker?.invalid || this.datePicker?.disabled);
            if (this.locationHours.length === 0) {
                this.time?.markAsTouched();
                this.time?.setErrors({ invalidTime: true });
            }
        } else if (input === 'date') {
            this.showDatePickerError = Boolean(this.datePicker?.disabled);
        }

        this.cd.markForCheck();
    }

    onSubmit(): void {
        if (this.states.processing) {
            return;
        }

        this.errorState = '';
        this.cd.markForCheck();

        if (!this.form) {
            return;
        }

        if (this.form.invalid || this.form.disabled) {
            FormService.markControlsAsTouched(this.form);

            if (this.datePicker?.invalid || this.datePicker?.disabled) {
                this.showDatePickerError = true;
                this.cd.markForCheck();
            }

            if (this.time?.invalid || this.time?.disabled) {
                this.showTimeError = true;
                this.cd.markForCheck();
            }
            return;
        }

        this.states.processing = true;

        const value = this.form.value;

        const testDriveDate = this.testDriveDate();
        if (!testDriveDate) {
            return;
        }

        // Hardcoded to UTC+2 (Swedish standard time)
        const timeZoneOffset = 2;
        const datetime = `${testDriveDate}T${leftFillNum(value.time, 5)}:00.0${timeZoneOffset}00Z`;
        const customNcgOriginName = window.location.host.replace('www.', '');

        const vehicle = value.vehicle?.lms ?? value.vehicle;

        const model: ITestDriveDto = {
            origin: customNcgOriginName,
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email.toLowerCase(),
            phone_mobile: `${value.selectedCountryCode}${value.phone_mobile}`,
            datetime,
            consent: value.consent || undefined,
            storeCode: value.location.store_code,
            vehicle,
            url: this.metaService.getAbsoluteUrlWithoutQueryString(),
            isUsed: !!this.usedCar,
            brand: this.usedCar?.make,
            model: this.usedCar?.model,
            list: this.listId,
            meta: {
                campaign: {
                    utm: this.trackingService.getUtmValue(),
                    title: this.campaign,
                },
            },
        };

        const vehicleName = this.usedCar ? `${this.usedCar.make} ${this.usedCar.model}` : value.vehicle.name;

        firstValueFrom(this.formService.submitTestDrive(model, vehicleName, this.isSidePanel))
            .then(() => {
                this.onSuccess();
            })
            .catch((err: AppHttpErrorResponse) => {
                this.states.processing = false;

                this.errorState = 'server';
                if (err.validationErrors.length) {
                    this.errorState = 'api';
                    if (this.form) {
                        FormService.markValidationErrors(err.validationErrors, this.form);
                    }
                }

                this.cd.markForCheck();
            })
            .then(() => {
                this.scrollService.scrollToElement(this.mainElement?.nativeElement, { block: 'center' });
            });
    }

    private testDriveDate(): string | undefined {
        const datetime = this.form?.value.datetime;

        if (!datetime) {
            return undefined;
        }

        const testDriveDate = `${datetime.year}-${leftFillNum(datetime.month, 2)}-${leftFillNum(datetime.day, 2)}`;
        return testDriveDate;
    }

    private onSuccess() {
        const url = this.redirect?.url;
        if (url) {
            this.router.navigateByUrl(url);
            return;
        }

        this.states.processing = false;
        this.states.submitted = true;
        this.cd.markForCheck();
    }

    get datePicker() {
        return this.form?.get('datetime');
    }

    get time() {
        return this.form?.get('time');
    }

    get location() {
        return this.form?.get('location');
    }

    get vehicle() {
        return this.form?.get('vehicle');
    }

    errors = (controlName: string) => FormService.errors(controlName, this.form);

    private isOpen(openingHours?: OpeningHours): boolean {
        if (!openingHours) {
            return false;
        }
        // Test to see if open_time contains 2 consecutive digits.
        // This is a simple way to test if it is open.
        // Typically open_time contains e.g. "lukket" or a timestamp like "10:00".
        return /\d{2}/m.test(openingHours.open_time ?? '');
    }

    private setLocationHours() {
        this.locationHours = [];
        const location: NgcApiLocation | undefined = this.location?.value;
        const testDriveDate = this.testDriveDate();

        if (!location || !testDriveDate) {
            return;
        }

        const datetime = new Date(testDriveDate);
        let weekDay = datetime.getDay();

        // Rewrite sunday to match ngbCalendar (ISO-8601)
        if (weekDay === 0) {
            weekDay = 7;
        }

        const weekDayName = WEEKDAY_NAME_MAP[weekDay];
        const openingHours = location.opening_hours?.find((x) => x.open_day === weekDayName);
        const isOpen = this.isOpen(openingHours);

        if (!isOpen || !openingHours) {
            console.warn('Location had no available opening day');
            return;
        }

        const { open_time, close_time } = openingHours;

        const splitTime = (time: string): number[] => {
            const [hour, minute] = time.split(':');
            return [Number(hour), Number(minute)];
        };

        const [openHour, openMinute] = splitTime(open_time ?? '');
        const [closeHour, closeMinute] = splitTime(close_time ?? '');

        // Check if it's possible to have the whole day booked by 1 hour sessions, or if some are broken up (e.g. 8:30 - 17:00)
        // It is always the last hour that is most important, so the last booking of the day, should align with the closing time
        // If 8:30 - 16:30 -> First booking is 8:30 - 9:30  ---  0 min offset
        // If 8:30 - 17:00 -> First booking is 9:00 - 10:00 --- 30 min offset start of day
        // If 9:00 - 17:00 -> First booking is 9:00 - 10:00 ---  0 min offset
        // If 9:00 - 17:30 -> First booking is 9:30 - 10:30 --- 30 min offset start of day

        // If minuteSign is 1, we know that our openMinute is higher than closeMinute, so we need to offset by 1 hour
        // If minuteSign is 1 or -1, we know that we must use the closeMinute as startMinute and endMinute, to align with closing time
        const minuteSign = Math.sign(openMinute - closeMinute);
        const hourOffset = minuteSign === 1 ? 1 : 0;
        const useClosingTime = minuteSign !== 0;

        for (let startTime = openHour; startTime < closeHour - hourOffset; startTime++) {
            this.locationHours.push({
                startHour: startTime + hourOffset,
                startMinute: useClosingTime ? closeMinute : openMinute,
                endHour: startTime + 1 + hourOffset,
                endMinute: useClosingTime ? closeMinute : openMinute,
            });
        }
    }
}
